import React,{useState} from 'react';
import classes from './UnitAboutIcon.module.css';
import {FaGraduationCap} from 'react-icons/fa';

import EduDegreeForm from './EduDegreeForm'


const UnitAboutIcon=(props)=>{


const [showAddForm, setShowAddForm] = useState(false);


  const showFormHandler=()=>{
     setShowAddForm(true);
  }

  const closeFormHandler=()=>{
     setShowAddForm(false);
     props.rerender();
  }




return (

<div className={classes.unitAboutIcon}>

   <div> <FaGraduationCap className={classes.aboutIcon} /> <div style={{fontWeight:"bold",color:"var(--themeColor)", fontSize:"20px",marginLeft:"5px"}}>EDUCATION</div> </div>  
   <button className={classes.editButtonAbout} onClick={showFormHandler}> +Add </button>

   { 
	showAddForm && <EduDegreeForm onPress={closeFormHandler}/>
   }


</div>
);


}
export default UnitAboutIcon;
