import classes from './ClassesContainer.module.css';
import React, { useEffect, useState } from 'react';
import ClassViewShort from './ClassViewShort_ht';
//import ClassDetailView from './ClassDetailView';
import { Route, Switch, useHistory } from 'react-router-dom';
import { getalluserclasses } from '../../../CommonApps/AllAPICalls';
import { BsChevronDown, BsChevronDoubleRight, BsChevronDoubleLeft } from 'react-icons/bs';
import { BiChevronsRight, BiChevronsLeft } from 'react-icons/bi';




const CalcStartEndValues = (totalClasses, totalPages, currentPageLength, pageNo) => {
   let x = 0;//general no of items in one page
   let y = 0;//last page content
   let startValue = 0;
   let endValue = 0;

   if (pageNo < totalPages) {

      startValue = (pageNo - 1) * currentPageLength + 1;
      endValue = startValue + currentPageLength - 1;

   }

   if (pageNo === totalPages) {

      startValue = totalClasses - currentPageLength + 1;
      endValue = totalClasses;

   }

   return { startValue, endValue };

}



const ClassesContainer = (props) => {

   const history = useHistory();

   const [classId, setClassId] = useState(null);

   //const [classURL, setClassURL] = useState(null);

   const [classPath, setClassPath] = useState(null);

   const [pageNo, setPageNo] = useState(1);



   const goToClassHandler = ({ classId }) => {
      setClassId(classId);
      setClassPath(`/home/dashboard/classes/${classId}`);
      history.push(`/home/dashboard/classes/${classId}`);
   }

   useEffect(() => {

      let classURL = window.location.href;
      let pattern = '/home/dashboard/classes/[1-9]+';
      let result = classURL.match(pattern);

      if (result) {
         let urlarray = classURL.split("/");
         let class_Id = urlarray[urlarray.length - 1];
         setClassId(class_Id);
         //setClassPath(result+"/");
         setClassPath("/home/dashboard/classes/" + class_Id + "/");
      }

   }, [classPath]);


   const [userClasses, getUserClasses] = useState(null);

   useEffect(() => {

      getalluserclasses({ getUserClasses, pageNo });

   }, [pageNo, props.rerender]);

   console.log("inside class container------------------", userClasses)

   const nextPageHandler = () => {

      if (userClasses !== null && userClasses.next !== null) {
         setPageNo(pageNo => pageNo + 1);
      }
   }


   const prevPageHandler = () => {

      if (userClasses !== null && userClasses.previous !== null) {
         setPageNo(pageNo => pageNo - 1);
      }

   }




   console.log("userClasses All View: ", userClasses);


   let currentPageLength = userClasses !== null && userClasses.results !== null && userClasses.results.length;
   let totalClasses = userClasses !== null && userClasses.count;
   let totalPages = userClasses !== null && userClasses.total_pages;
   let { startValue, endValue } = CalcStartEndValues(totalClasses, totalPages, currentPageLength, pageNo);

   //let student = props.userData.id !==null && props.userData.usertype === 2 ;	


   console.log("userClasses: ", userClasses);



   return (


      <div className={classes.parentContainer}>



         <Route exact path='/app/home/dashboard'>


            <div className={classes.sessionInfo}> Sessions Information</div>
            <div className={classes.monitorBoxes}>


               <div className={classes.monitorOneBox}>
                  <div className={classes.boxnum}> 2</div>
                  <div className={classes.boxtitle}> Upcoming </div>
               </div>
               <div className={classes.monitorOneBox}>
                  <div className={classes.boxnum}> 5</div>
                  <div className={classes.boxtitle}> Completed </div>
               </div>
               <div className={classes.monitorOneBox}>
                  <div className={classes.boxnum}> 7</div>
                  <div className={classes.boxtitle}> Total </div>
               </div>



            </div>




            <div className={classes.timeLine}>
               Showing {startValue} to {endValue} sessions of
               <i className={classes.infoText_i}>
                  <button type="button" className={classes.navButton} onClick={prevPageHandler}> <BsChevronDoubleLeft /> </button>
                  <span className={classes.dateText}> total  {userClasses !== null && userClasses.count} </span>
                  <button type="button" className={classes.navButton} onClick={nextPageHandler}> <BsChevronDoubleRight /></button>
               </i>
            </div>


            {userClasses !== null && userClasses.results.map((oneClass, index) => {

               let booked = oneClass.students.length > 0;

		  console.log("booked: ", booked);
               if (booked) {
                  return <ClassViewShort key={index}
                     oneClass={oneClass}
                     userData={props.userData}
                     rerender={props.rerender}
                     classDetailHandler={goToClassHandler}
                  />
               } else {

                  return null;

               }

            })

            }
         </Route>

         {/*
         <Route  exact path={classPath} >   
	   
                <ClassDetailView userData={props.userData} 
	                      passOneClassMountInfo={props.passOneClassMountInfo}
	                      classId={classId}
	                      />	   
         </Route>
         */}

      </div>

   );




}

export default ClassesContainer;
