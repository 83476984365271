import classes from "./Faqlist.module.css";
import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";

function Faqlist() {
  const initialData = [
    {
      question: "1. What is HelloToppers?",
      answer:
        "Hello Toppers is a digital platform that connects Topper Mentors (i.e those who have achieved success in various competitive exams) with aspirants who seek guidance and mentorship.Our mission is to help students achieve their goals by to bridge the gap between successful mentors and students who need expert advice and inspiration",
    },
    {
      question: "2. Who can become a Topper Mentor?",
      answer:
        "To become a Topper Mentor, you must have qualified any competitive exams of national or state importance. . We are looking for individuals who are passionate about mentoring and sharing their knowledge to motivate others.",
    },

    {
      question: "3. What are the benefits of becoming a Topper Mentor?",
      answer:
        "As a Topper Mentor, you will receive competitive compensation, have the opportunity to share your success, mentor multiple aspiring individuals, expand your professional network, give back to society, and be a role model.",
    },

    {
      question: "4. How does the application process work?",
      answer:
        "The process is simple: Just register yourself as a topper mentor and fill your details , which is then reviewed by our team. If approved, you'll be onboarded to the platform and start mentoring students.",
    },

    {
      question: "5. How much time do I need to commit as a Topper Mentor?",
      answer:
        "The time commitment will vary based on your availability and the number of students you want to mentor. We offer flexible scheduling options to fit your personal and professional life.",
    },

    {
      question: "6. How are mentors compensated?",
      answer:
        "Mentors receive rewards based on their engagement and mentoring activities. Details of the compensation structure will be briefed during the onboarding process.",
    },

    {
      question: "7. Can I get the student details before mentoring?",
      answer:
        "Yes, You can view the student's profile prior to mentoring. However, As per the privacy policy, direct contact with students is strictly prohibited and you should not share your contact information with them, even during one-on-one sessions.",
    },

    {
      question:
        "8. What are the area ,where I need to mentor one aspirants?",
      answer:
        "There is no such specific area , However you just need to guide the aspirant based on his/her queries. Apart from this, you can advise and guide on those parameters which you feel important to succed in the exams",
    },

    {
      question: "9. What if I have questions or need help while mentoring?",
      answer:
        "Our team is always here to support you. You can reach out to us via calls, email or through our support portal for any questions or assistance you may need during your mentoring journey.",
    },

    {
      question: "10. How can I apply to become a Topper Mentor?",
      answer: "To apply, click the \"Apply Now\" button on our \"Join as a Topper\" page and register yourself. Our team will review your application and contact you with the next steps."
    },
  ];

  const [faqData, setFaqData] = useState(initialData);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

  const toggleAnswer = (index) => {
    setSelectedQuestionIndex(index === selectedQuestionIndex ? null : index);
  };

  return (
    <div className={classes.faqContainer}>
      <div className={classes.mainTitleContainer}>
        Frequently Asked Questions
      </div>

      <div className={classes.mainContainer}>
        {faqData.map((faq, index) => (
          <div
            key={index}
            className={classes.questionsContainer}
            onClick={() => toggleAnswer(index)}
          >
            <div className={classes.queContainer}>
              <div className={classes.que1}>{faq.question}</div>
              <BsChevronDown className={classes.arrow} />
            </div>

            {selectedQuestionIndex === index && (
              <div className={classes.ans1}>{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faqlist;
