import classes from './Success.module.css';
import boy1 from "../../../../Home/boy 1.jpeg";
import girl1 from '../../../../Home/girl 1.jpeg';
const success = () => {
    return (
        <div className={classes.mainContainer}>
            <div className={classes.topContainer}>
                <div className={classes.headingContainer}>
                    Success Stories
                </div>
                <div className={classes.titleContainer}>
                    What our students are saying
                </div>
            </div>
            <div className={classes.bottomContainer}>
                <div className={classes.cardContainer}>
                    <div className={classes.descriptionContainer}>
                        Coming from a small village, I never thought I’d have access to such quality mentorship. The insights and encouragement I received through the MargDarshak program have made all the difference in my preparation. I feel empowered to achieve my dreams."

                    </div>
                    <div className={classes.nameContainer}>
                        Priyanka Jena, OPSC Aspirant

                    </div>
                    <div className={classes.imageContainer}>
                        <img src={girl1} className={classes.imageInput} />
                    </div>
                </div>
                <div className={classes.cardContainer}>
                    <div className={classes.descriptionContainer}>
                        The MargDarshak program has been a game-changer for me. My mentor not only guided me through exam strategies but also inspired me to dream bigger. I’m now more confident and focused on my goals, knowing I have the support of someone who’s been in my shoes."

                    </div>
                    <div className={classes.nameContainer}>
                        Rajiv R, UPSC Aspirant

                    </div>
                    <div className={classes.imageContainer}>
                        <img src={boy1} className={classes.imageInput} />
                    </div>
                </div>


            </div>
        </div>
    )
}

export default success;