import classes from "./ProfileCard.module.css";
import { BsHeart, BsFillGeoAltFill } from "react-icons/bs";
import { FaGraduationCap, FaRupeeSign } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { BsFillStarFill, BsTranslate, BsLayoutTextWindowReverse } from 'react-icons/bs';
import { FaChalkboardTeacher } from "react-icons/fa";
import Rating from "./Rating"; // Make sure this matches the export in Rating.js
import coaching from './coaching.png';
import language from "./Language_icon.png";
const ProfileCard = (props) => {
    console.log("topper profile: ", props.topperProfile);

    function extractNumberBeforeParenthesis(inputString) {
        const match = inputString.match(/(\d+(\.\d+)?)\s*\(/);
        return match ? parseFloat(match[1]) : null;
    }


    return (
        <div className={classes.profileCardDetails}>
            <div className={classes.leftSide}>
                <div className={classes.profilePic}>
                    <img
                        src={props.topperProfile.profile_image}
                        className={classes.mainprofilePic}
                        alt="Profile"
                    />

                </div>
                <div className={classes.designationContainer}>
                    UPSC Mentoring
                </div>
            </div>

            <div className={classes.rightSide}>
            <div className={classes.expertContainer}>{props.topperProfile.profilehighlighttag}</div>

                <div className={classes.topContainer}>
                    <div className={classes.NameContainer}>{props.topperProfile.firstname}</div>

                </div>


                <div className={classes.midContainer}>
                    <FaGraduationCap className={classes.location} />
                    <div className={classes.locationName}>
                        <b>{props.topperProfile.profilehighlighttag}</b>
                    </div>
                </div>

                <div className={classes.midContainer}>
                    <FaChalkboardTeacher className={classes.location} />

                    <div className={classes.locationName} style={{ color: "black" }}>
                        <b>{props.topperProfile.mentoringtag}</b>
                    </div>
                </div>

                <div className={classes.midContainer}>
                    <BsPeopleFill className={classes.location} />
                    <div className={classes.locationName}>
                        {props.topperProfile.nooflessions + " Sessions. "}
                        {props.topperProfile.noofstudents + " Students"}
                    </div>
                </div>

                <div className={classes.bookMainContainer}>
                    <div className={classes.languageContainer}>
                        <BsTranslate className={classes.location}/>
                        <div className={classes.lang}>{props.topperProfile.languages}</div>
                    </div>
                </div>
                <div className={classes.ratingContainer}>
                    <div className={classes.ratingStar}>
                        <Rating
                            rating={extractNumberBeforeParenthesis(props.topperProfile.noofstars)}
                        />
                    </div>
                    <div className={classes.ratingNumber}>{props.topperProfile.noofstars}</div>
                </div>



            </div>

        </div>
    );
};

export default ProfileCard;
