import classes from './margadarshak/Faq.module.css';
import { useState } from 'react';
import { BsChevronDown } from "react-icons/bs";

const Faq = () => {
    const initialData = [
        {
            question: "1. How do I book a session with a topper mentor?",
            answer:"To book a session, browse through the list of available topper mentors, select the mentor you wish to connect with, choose a suitable date and time from their calendar, and complete the booking process by making the payment. You will receive a confirmation email with all the session details."
        },
        {
            question: "2. What topics can I discuss with a topper mentor?",
            answer:"You can discuss any academic topics, exam preparation strategies, time management tips, subject-specific doubts, or guidance on study plans. Each topper mentor has specific areas of expertise mentioned on their profile to help you choose the best fit for your needs."
        },
        {
            question: "3. Can I reschedule or cancel my booking if I am unable to attend?",
            answer:"Yes, you can reschedule or cancel your session up to 24 hours before the scheduled time. To do so, log into your account, go to your bookings, and select the reschedule or cancel option. For cancellations, a refund policy will apply as per the platform’s guidelines."
        },

        {
            question: "4. How long is each mentoring session, and what format do they follow?",
            answer:
                "Each mentoring session typically lasts between 30 to 60 minutes, depending on the mentor and the package you choose. Sessions are conducted online through video calls, ensuring a convenient and interactive experience. The format is personalized to address your specific needs and queries.",
        },
        {
            question: "5. What should I prepare before my session with a topper mentor?",
            answer:"To make the most of your session, prepare a list of questions or topics you want to discuss. Be clear about the areas you need help with, and have any relevant study material or notes ready. This will help your mentor provide the most effective guidance during your time together.",
        }

       
    ];

    const [faqData, setFaqData] = useState(initialData);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

    const toggleAnswer = (index) => {
        setSelectedQuestionIndex(index === selectedQuestionIndex ? null : index);
    };

    return (
        <div className={classes.faqContainer}>
            <div className={classes.mainTitleContainer}>
                FAQ

            </div>

            <div className={classes.mainContainer}>
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={classes.questionsContainer}
                        onClick={() => toggleAnswer(index)}
                    >
                        <div className={classes.queContainer}>
                            <div className={classes.que1}>{faq.question}</div>
                            <BsChevronDown className={classes.arrow} />
                        </div>

                        {selectedQuestionIndex === index && (
                            <div className={classes.ans1}>{faq.answer}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}


export default Faq