import classes from './MeetMentors.module.css';
import manas from '../../../../Home/Manas.jpeg';
import vikas from '../../../../Home/Vikash.jpeg';
import pankaj from '../../../../Home/Pankaj.jpeg';

const MeetMentors = () => {
    return (
        <div className={classes.mainConatiner}>
            <div className={classes.headingContainer}>
                Meet the Topper mentors
            </div>
            <div className={classes.descriptionContainer}>
                Our MargDarshaks
            </div>
            <div className={classes.imageContainer}>
                <div className={classes.cardContainer}>
                    <div className={classes.imageInput}>
                        <img src={manas} className={classes.image}/>
                    </div>
                    <div className={classes.nameContainer}>
                        Manas Panda
                    </div>
                </div>
                <div className={classes.cardContainer}>
                    <div className={classes.imageInput}>
                    <img src={vikas} className={classes.image}/>
                    </div>
                    <div className={classes.nameContainer}>
                        Vikas Kumar
                    </div>
                </div>
                <div className={classes.cardContainer}>
                    <div className={classes.imageInput}>
                    <img src={pankaj} className={classes.image}/>
                    </div>
                    <div className={classes.nameContainer}>
                        Pankaj Sahu

                    </div>
                </div>
            </div>

        </div>
    )
}

export default MeetMentors;
