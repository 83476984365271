import classes from './topBlock.module.css';
import img2 from './sub main.png';

const topBlock = () => {
    return (
        <div className={classes.mainContainer}>
            <div className={classes.topContainer}>
                <div className={classes.headingContainer}>
                    Empowering Aspirations, One Mentor at a Time
                </div>
            </div>
            <div className={classes.bottomContainer}>


                <div className={classes.leftContainer}>


                    <div className={classes.titleContainer}>
                        Program Overview:
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={classes.paraContainer}>
                            <b>
                                Margdarshak&nbsp;
                            </b> 
                            is a unique free group online mentoring initiative designed to bridge the gap between rural, marginalized students and top-ranking exam achievers.
                        </div>
                        <div className={classes.paraContainer}>

                            This program aims to provide structured career guidance and exam preparation strategies to students who otherwise lack access to quality mentorship.

                        </div>
                        <div className={classes.paraContainer}>

                            By leveraging the experience and success of toppers, the program seeks to inspire and guide students in their academic and career journeys, ensuring that geographical and socio-economic barriers do not hinder their potential.
                        </div>

                    </div>
                </div>


                <div className={classes.rightContainer}>
                    <div className={classes.imageContainer}>
                        <img src={img2} className={classes.backGroundArrow} />
                    </div>
                </div>
            </div>




        </div>
    )
}

export default topBlock