import classes from "./introduction.module.css";
import img1 from './introduction background.png';
import img2 from './main picture.png';
import { useHistory } from "react-router-dom";

const Introduction = () => {
    const history = useHistory();
    const registration = () => {
        history.push('/registerasstudent');
    }

    return (
        <div className={classes.MainIntroductionBlockParent}>
            <div className={classes.IntroductionBlockParent}>
                <div className={classes.leftContainer}>
                    <div className={classes.headingContainer}>
                        <b>
                            MargDarshak:
                        </b>
                        &nbsp;Pathfinders for Exam Aspirants
                    </div>
                    <div className={classes.titleContainer}>
                        Empowering Rural Students with Expert
                        <br />
                        Guidance from Topper Mentors
                    </div>
                    <div className={classes.buttonContainer} onClick={registration}>
                        Join the Program
                    </div>
                </div>
                <div className={classes.rightContainer}>
                    <div className={classes.imageContainer}>
                        <img src={img2} className={classes.backGroundArrow} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Introduction;
