
import { useHistory } from "react-router-dom";
import classes from "./ExamBoxSlide.module.css";
import basewebURL from "../../../../basewebURL";

const OneExamCard = (props) => {

  const history = useHistory();
  let profilepath = basewebURL+"/topperprofile/"+props.card.id;

  const openNewURL = () => {
    window.open(profilepath,"_blank");
  }



  return <div className={classes.card} onClick={openNewURL}>
    <img
      src={props.card.image}
      className={classes.img}
      style={{ aspectRatio: props.aspectRatio }}
    />
    <div className={classes.title}>{props.card.title}</div>
    <div className={classes.description1}>{props.card.description}</div>
    <div className={classes.ViewToppersBtn} >{props.card.viewDetails}</div>
  </div>

}

export default OneExamCard;
