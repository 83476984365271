import classes from "./Testimonial.module.css";
import React, { useRef, useEffect } from "react";

import bookAppointment from "./bookAppointment.jpg";
import t1 from "./t1.jpg";
import t2 from "./t2.jpeg";
import t3 from "./t3.jpg";

import boy1 from "./boy 1.jpeg";
import boy2 from './boy 2.jpeg';
import girl1 from './girl 1.jpeg';

import { AiFillTwitterCircle } from "react-icons/ai";

function Testimonial() {
  // const divRef = useRef(null);

  // // Automatically scroll the div to the right on component mount
  // useEffect(() => {
  //   const scrollDiv = divRef.current;
  //   if (scrollDiv) {
  //     scrollDiv.scrollLeft = scrollDiv.scrollWidth - scrollDiv.clientWidth;
  //   }
  // }, []);

  return (
    <div className={classes.TestimonialParent}>
      <div className={classes.ourClientDetails}>
        {/* <div className={classes.clientTitle}> Voices of Change </div> */}
        <div className={classes.clientSubTitle}>
          {" "}
          Words from Our Achievers..
        </div>

        <div className={classes.clientFeedbackContainer}>
          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={boy1} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>

            <div className={classes.clientDetails}>
              At the beginning, I was clueless about the entire UPSC preparation. However, the direct interaction
              with UPSC toppers helped me decoding the entire syllabus and boosted my confidence.
            </div>

            <div className={classes.clientName}>Rajiv Ranjan</div>

            <div className={classes.clientStatus}>
              UPSC Aspirant
            </div>
          </div>

          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={girl1} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>
            <div className={classes.clientDetails}>
              I attended session on hellotoppers where the OPSC toppers provided me with a detailed preparation
              plan, helped me in selecting the right study materials thereby making my preparation easier and
              smoother.
            </div>

            <div className={classes.clientName}>Priyanka Jena </div>

            <div className={classes.clientStatus}>OPSC Aspirant</div>
          </div>


          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={boy2} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>
            <div className={classes.clientDetails}>
              The 1-on-1 sessions with GATE toppers from Hellotopper provided me the right strategies to attempt
              questions in exam, helping me avoid negative marking and optimize my score to the maximum.
            </div>

            <div className={classes.clientName}>Dinesh Thakur</div>

            <div className={classes.clientStatus}>
              GATE Aspirant
            </div>
          </div>



        </div>
      </div>
    </div>
  );
}

export default Testimonial;
