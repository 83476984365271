import classes from "./Testimonial.module.css";
import React, { useRef, useEffect } from 'react';


import Arindam from "./Arindam.jpeg";

import { AiFillTwitterCircle } from "react-icons/ai";
import manas_panda from "../Home/Manas.jpeg";
import pankaj from '../Home/Pankaj.jpeg';
import vikash from '../Home/Vikash.jpeg';

function Testimonial() {

  // const divRef = useRef(null);

  // // Automatically scroll the div to the right on component mount
  // useEffect(() => {
  //   const scrollDiv = divRef.current;
  //   if (scrollDiv) {
  //     scrollDiv.scrollLeft = scrollDiv.scrollWidth - scrollDiv.clientWidth;
  //   }
  // }, []);


  return (
    <div className={classes.TestimonialParent}>
      <div className={classes.ourClientDetails}>
        {/*
        <div className={classes.clientTitle}> Voices of Change </div>
	 */}
        <div className={classes.clientSubTitle}>What toppers say..</div>




        <div className={classes.clientFeedbackContainer} >

          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={pankaj} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>

            <div className={classes.clientDetails1}>
              Through Hellotopper, I can support students preparing for Civil Services with tailored advice and
              study plan. Helping them overcome challenges and excel in their exams is truly satisfying and way to
              give back to society
            </div>

            <div className={classes.clientName}>Pankaj Sahu</div>

            <div className={classes.clientStatus}>
              UPSC - 2018, AIR - 79
            </div>
          </div>

          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={vikash} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>
            <div className={classes.clientDetails2}>
              Mentoring on Hellotopper is a great way to help aspirants navigate their preparation journey.
              Sharing my experiences and techniques makes a real difference in their confidence and success.
            </div>

            <div className={classes.clientName}>Vikash Kumar </div>

            <div className={classes.clientStatus}>
              GATE - EE, AIR - 34
            </div>
          </div>

          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={manas_panda} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>
            <div className={classes.clientDetails3}>
              It gives me immense pleasure to guide and motivate the aspirants because their success feels like
              creating success stories through me.
            </div>

            <div className={classes.clientName}>Manas Kumar Panda</div>

            <div className={classes.clientStatus}> UPSC - 2012 , AIR - 5</div>
          </div>



        </div>



      </div>
    </div>
  );
}

export default Testimonial;
