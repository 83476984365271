import React from 'react';
import styles from './Rating.module.css';

const Rating = ({ rating }) => {
  const getStarFill = (starIndex) => {
    const rate = Number(rating);
    console.log(rating,typeof(rating))
    const fillLevel = rate - starIndex;
    if (fillLevel >= 1) return '100%';      // Full
    if (fillLevel >= 0.75) return '75%';    // 3/4
    if (fillLevel >= 0.5) return '50%';     // 1/2
    if (fillLevel >= 0.25) return '25%';    // 1/4
    return '0%';                           // Empty
  };

  return (
    <div className={styles.stars}>
      {[...Array(5)].map((_, index) => (
        <div key={index} className={styles.star}>
          <div
            className={styles.filledStar}
            style={{ width: getStarFill(index) }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default Rating;
