import React, { useState, useEffect } from "react";
import classes from "./ClassViewShort_ht.module.css";
import { BsFillCheckSquareFill,BsPerson  } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import {
  BsFillTrashFill,
  BsPeopleFill,
  BsFillCameraVideoFill,
  BsLink45Deg,
  BsThreeDotsVertical,
  BsClockFill,
  BsCheck,
  BsCheck2
} from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { GiHazardSign } from "react-icons/gi";
//import {FiExternalLink} from 'react-icons/fi';
//import {getuserbyId, deletedashboardcourses} from '../../../../../CommonApps/AllAPICalls';

import studentimg from './file.png';

import { MdDoubleArrow } from "react-icons/md";

import { GiCheckMark } from "react-icons/gi";


import ClassCardDropDown from './ClassCardDropDown';
import { RiMoneyDollarCircleLine } from "react-icons/ri";



const formatLocalTime = ({datetime}) => {

    //const [userTimeZone, setUserTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);        


    let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = new Date(datetime);
    const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: userTimeZone }));
    const formattedDate = localDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const formattedTime = localDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return `${formattedTime}`;
  };


 const isDatetimeInPast = ({ datetime }) => {
    let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = new Date(datetime);
    const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: userTimeZone }));
    localDate.setMinutes(localDate.getMinutes() + 30);	 
    const currentTime = new Date();
    console.log("localDate: ", localDate);
    console.log("currentTime: ", currentTime);	 
    return localDate < currentTime;
 };









const formatLocalDate = ({datetime}) => {

    //const [userTimeZone, setUserTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);        


    let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = new Date(datetime);
    const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: userTimeZone }));
    const formattedDate = localDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const formattedTime = localDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return ` ${formattedDate}`;
  };




const ClassViewShort = (props) => {
  let history = useHistory();
  const [style, setStyle] = useState({
    primary: "lightgrey",
    secondary: "lightgrey",
    topBarBottomColor: "lightgrey",
    boxBkgColor: "white",
    rightButtonColor: "var(--themeColor)",
    codeColor: "grey",
    subjectColor: "grey",
    boxShadow: "grey 0px 3px 8px",
    fieldColor: "grey",
    fieldValueColor: "grey",
    borderColor: "lightgrey",
  });

  const courseSwitchHandler = () => {
    localStorage.setItem("selectedClassId", props.Class.id);
    props.rerender();
    history.push("/class/detail");
  };

  const [selectedClass, setSelectedClass] = useState(
    localStorage.getItem("selectedClassId")
  );

  //const [statusBkgColor, setStatusBkgColor] = useState('#25D366');

  const [showSelectScreen, setShowSelectScreen] = useState(false);

  const moveToSubject = () => {
    //   if(Number(props.Course.id) === Number(selectedCourse)){
    //   history.push('/class/detail');
    //  }
    //  if(Number(props.Course.id) !== Number(selectedCourse) ){
    //     setShowSelectScreen(true);
    // }
  };

  const closeSelectScreen = () => {
    setShowSelectScreen((showSelectScreen) => false);
  };

  //const ApproveHandler = (userId)=>{
  //let enrollId = userId;
  //let courseId = props.Course.id;
  //enrolledstudents.push(enrollId);
  //putcourseenroll({courseId, enrolledstudents});
  //showEnrollStatus(false);
  //}

  const courseSwitchAndMoveHandler = () => {
    //localStorage.setItem('preferredCourseId', props.Course.id);
    //setShowSelectScreen(showSelectScreen=>false);
    //localStorage.setItem('preferredCourseId', props.Course.id);
    // moveToSubject();
    props.rerender();
    history.push("/class/detail");
  };

  //selectedCourse !== null && Number(selectedCourse) === props.Course.id &&

  /*    
    useEffect(()=>{
       let deepColor='#00AFF0';
       let lessDeep='#9de2fc';
       let lightColor='white';


      if(selectedClass !== null && Number(selectedClass) === props.Class.id){
            setStyle(style=>({
		    primary: deepColor, 
		    secondary: lightColor,
		    topBarBottomColor:lessDeep,
		    boxBkgColor: lightColor,
		    rightButtonColor: deepColor,
		    codeColor: deepColor,
		    subjectColor: deepColor,
		    boxShadow: `grey 0px 3px 8px`,
		    fieldColor: deepColor,
		    fieldValueColor:deepColor,
		    borderColor:lessDeep

	    }));
      };


    return()=>{
       

    }


    },[props.Class.id, selectedClass]);
    */

  const deleteCourseHandler = () => {};

  const enrollInfoButtonHandler = () => {};

  //const [enrollRequests,setEnrollRequests] = useState(false);
  //const [enrollStatus, setEnrollStatus] = useState(false);

  let NumToMonth = [
    "N/A",
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const classcardHandler = () => {
    console.log("class card click");
  };

  const joinClassHandler = () => {
    console.log("join class handler");
  };

  const viewClassDetailHandler = () => {
     let classId = props.oneClass.id;
     props.classDetailHandler({classId});
  };




  const openMeetingRoomHandler = () => {
    let meetingLink = props.oneClass.meetingLink;
    window.open(meetingLink, "_blank");
  };

    const [showDropDownButtons, setShowDropDownButtons] = useState(false);

  const DropDownButtonsHandler=()=>{

     setShowDropDownButtons(showDropDownButtons=>true);

  }


  const closeDropDownHandler=()=>{

   setShowDropDownButtons(showDropDownButtons=>false);

  }


  let datetime = props.oneClass.datetime;
  //let userShowObject =  

   let userId = props.userData.id;
   let hostId = props.oneClass.host.id;
   let studenId = props.oneClass.students[0].id;

   let displayUser = (Number(userId) === Number(hostId))? props.oneClass.students[0]: props.oneClass.host;	













     console.log("oneClass: ", datetime);

  return (
    <div className={classes.courseViewDashboard} >

     	<div className={classes.timeDiv}>  
              <div className={classes.time}> {formatLocalTime({datetime})}  </div>
	      <div className={classes.date}>  {formatLocalDate({datetime})} </div>
	</div>


	<div className={classes.timeDiv2}>
              <div className={classes.time}>  <span className={classes.duration}>30 mins  </span> </div>
              <div className={classes.examName}> {props.oneClass.host.mentoringexamname} </div>
        </div>


	<div className={classes.personDiv}>  
	    <img src={displayUser.profile_image}  className={classes.imageTag}/>

	    <div className={classes.nameOfperson}> {displayUser.firstname} </div>
	</div>

        { !isDatetimeInPast({datetime}) &&
	<div className={classes.buttonDiv} onClick={openMeetingRoomHandler}>              
	  <button type="button" className={classes.joinButton}> Join Meeting </button>
	</div>  
       }

        { isDatetimeInPast({datetime}) &&
        <div className={classes.buttonDiv} onClick={openMeetingRoomHandler}>
          <button type="button" className={classes.joinButtonComplete} disabled={true}> Completed </button>
        </div>
       }






    </div>
  );
};

export default ClassViewShort;
