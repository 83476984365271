

import classes from "./OneExamCardMobile.module.css";
import { useHistory } from "react-router-dom";

const OneExamCard=(props)=>{
const history = useHistory();
const openNewURL=()=>{

 window.open(props.card.link,'_blank');

}





return  <div className={classes.card}
  onClick={()=>{
    if (props.card.value !== undefined){
      props.setSelectFindTopper(props.card.value);
      console.log(props.card.value);
      history.push("/findtoppers");
    }
    else{
      window.open(props.card.link,'_blank');
    }
  }}

>
             <img
               src={props.card.image}
               className={classes.img}
	       style={{aspectRatio:props.aspectRatio}}
                  />
              <div className={classes.title}>{props.card.title}</div>
              <div className={classes.description1}>{props.card.description}</div>
              <div className={classes.ViewToppersBtn} >{props.card.viewDetails}</div>
        </div>

}

export default OneExamCard;
