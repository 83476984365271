import classes from './Footer.module.css';
import { useHistory } from "react-router-dom";


const Footer = () => {
    const history = useHistory();
    const contactHandler = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        history.push('/contactus');
    }
    return (
        <div className={classes.mainContainer}>
            <div className={classes.headingContainer}>
                Ready to Make a Difference?
            </div>
            <div className={classes.descriptionContainer}>
                Join the MargDarshak program today—because your guidance can transform lives and build a brighter future for every student
            </div>
            <div className={classes.buttonContainer} onClick={contactHandler}>
                Join Us in Changing Lives
            </div>
        </div>
    )
}

export default Footer;