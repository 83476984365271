import classes from './Home.module.css';
import BookIcon1 from "./choose_a_topper.png";
import BookingIcon from "./book_a_session.png";
import UserBooking from "./book_a_session.png";
import get1to1guidance from './get1to1guidance.png';
import innerDiv from "../Website.module.css";



const HowItWorks = () => {


  return <div className={classes.HowitWorksContainer}>
    <div className={classes.HowitworksTitle}>How It Works ?</div>

    <div className={innerDiv.innerDiv}>

      <div className={classes.helloTopContainer}>
        <div className={classes.paarent}>
          <div className={classes.title}>Step 1 :</div>

          <div className={classes.logo}>
            <img src={BookIcon1} className={classes.imagesExam} />
          </div>
          <div className={classes.title}>Choose Your Topper</div>
          <div className={classes.description}>
            View the profile of all the toppers and select one that aligns
            with your needs.
          </div>
        </div>

        <div className={classes.paarent}>
          <div className={classes.title}>Step 2 :</div>

          <div className={classes.logo}>
            <img src={BookingIcon} className={classes.imagesExam} />
          </div>
          <div className={classes.title}>Book a Session:</div>
          <div className={classes.description}>
            Choose a time slot that suits you and book a session with the
            topper of your choice.
          </div>
        </div>

        <div className={classes.paarent}>
          <div className={classes.title}>Step 3 :</div>

          <div className={classes.logo}>
            <img src={get1to1guidance} className={classes.imagesExam} />
          </div>
          <div className={classes.title}>Get 1 to 1 Guidance:</div>
          <div className={classes.description}>
            Connect with topper via 1 to 1 online Video session and clarify  all your queries to succeed in your exam .
          </div>
        </div>

      </div>


    </div>

  </div>


}


export default HowItWorks;
