import { useState, useEffect } from 'react';
import classes from "./findTopperscard.module.css";
import office3 from "./TeenaBhabi.png";
import { BsFillStarFill, BsTranslate, BsLayoutTextWindowReverse } from 'react-icons/bs';
import basewebURL from '../../basewebURL';
import { FaRupeeSign } from "react-icons/fa";

import { BsPeopleFill } from 'react-icons/bs';
import Rating from './TopperProfile/Reshwanth/Rating';

const TopperCard = (props) => {


  function extractNumberBeforeParenthesis(inputString) {
    const match = inputString.match(/(\d+(\.\d+)?)\s*\(/);
    return match ? parseFloat(match[1]) : null;
}

  let profilepath = basewebURL + "/topperprofile/" + props.user.id;

  const showTopperProfileHandler = () => {
    window.open(profilepath, "_blank");
  }

  const [aboutHeight, setAboutHeight] = useState("100px");

  const readMoreHandler = () => {
    setAboutHeight(aboutHeight => "auto");
  }

  const hideHandler = () => {
    setAboutHeight(aboutHeight => "100px");
  }





  return (
    <div className={classes.parentCard}>
      <div className={classes.leftContainer}>
        <div className={classes.picContainer}>
          <img src={props.user.profile_image} className={classes.personpic} />
        </div>
        <div style={{ fontSize: "16px", marginTop: "10px" }}> <b>{props.user.mentoringtag_second}</b> </div>
        {/* <div className={classes.price}>
          <FaRupeeSign size={17} />
          <span style={{ fontSize: "18px" }}>{props.user.hourlyrate} / 100100min </span>
        </div>

        <button className={classes.bookBtnContainer1} type="button" onClick={showTopperProfileHandler}>
          <div className={classes.bookTitle}>Book Now</div>
        </button> */}

        {/*
          <div className={classes.pricephr}>INR/H</div>
	*/}
      </div>

      <div className={classes.midContainer}>
        <div className={classes.FeaturedTitleBtn}>{props.user.profilehighlighttag}</div>
        <div className={classes.Username} onClick={showTopperProfileHandler}>{props.user.firstname}</div>

        <div className={classes.langContainer}>
          <div className={classes.l1Container}>
            <BsTranslate size={20} className={classes.l1icon} />
            <div className={classes.l1Lang}><b>Languages:</b> {props.user.languages}</div>
          </div>
        </div>

        <div className={classes.lessonContainer}>
          <BsPeopleFill className={classes.lessionIcon} size={17} />
          <div className={classes.firstContainer}>
            <div className={classes.lessionTitle}>Sessions :</div>
            <div className={classes.lessionDetails}>{props.user.nooflessions}</div>
          </div>
          <div className={classes.secContainer}>
            <div className={classes.lessionTitle}>Students :</div>
            <div className={classes.lessionDetails}>{props.user.noofstudents}</div>
          </div>
        </div>



        <div className={classes.aboutuserDetails} style={{ minHeight: "50px", height: "50px" }} >
          {props.user.about + "..."}
        </div>

        <div className={classes.buttonContainer}>
          {aboutHeight === "100px" &&
            <button type="button" className={classes.readMoreBtn} onClick={showTopperProfileHandler}>View profile</button>
          }

          <button className={classes.bookBtnContainer1} type="button" onClick={showTopperProfileHandler}>
            <div className={classes.bookTitle}>Book Now</div>
          </button>
        </div>



        {aboutHeight === "auto" &&
          <button type="button" className={classes.readMoreBtn} onClick={hideHandler}>Hide Text</button>
        }

      </div>

      <div className={classes.rightContainer}>
        <div className={classes.topContainer}>
          <div className={classes.ratingContainer}>
          <Rating
                            rating={extractNumberBeforeParenthesis(props.user.noofstars)}
                        />

            {/* <div className={classes.starContainer}>
              <BsFillStarFill style={{ color: "#FFDF00" }} size={18} />
            </div>*/}
            <div className={classes.star}>{props.user.noofstars}</div> 
          </div>
          {/* <div className={classes.totalRating}>(Ratings:{props.user.totalratings})</div> */}

          <button className={classes.bookBtnContainer} type="button" onClick={showTopperProfileHandler}>
            <div className={classes.bookTitle}>Book Now</div>
          </button>

        </div>

        <div className={classes.bottomContainer}>
          {/* <div className={classes.iconContainerheart}></div> */}
        </div>
      </div>
    </div>
  );
}

export default TopperCard;

