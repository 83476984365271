import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import classes from './PayForm.module.css';
import { GiCheckMark } from "react-icons/gi";
import { checkifuserexistToPay, bookslotbystudent, paymentInitiate, createaccountstudent, transactionStatusPhonePe } from '../../../../CommonApps/AllAPICalls';
import FadeLoader from "react-spinners/BarLoader";
import { Logout } from '../../../../CommonApps/Logout';
import flag from "../flag.jpeg";
import { v4 as uuidv4 } from 'uuid';
import sha256 from "crypto-js/sha256";
import { FaRupeeSign } from "react-icons/fa";
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';



const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const BookingForm = (props) => {
    const axios = require('axios');
    let color = "var(--themeColor)";
    const inActiveColor = {
        color: "grey",
        backgroundColor: "lightgrey",
        borderStyle: "none",
        borderWidth: "1px"
    }
    const activeColor = {
        color: "white",
        backgroundColor: "var(--themeColor)",
        borderStyle: "none",
        borderWidth: "1px"
    }
    const history = useHistory();
    const initialFormData = {
        name: "",
        phoneno: "",
        amount: "",
        muid: ""
    };
    const initialDetailsFormData = {
        username: "",
        phoneno: "",
        email: ""
    };
    const [formData, updateFormData] = useState(initialFormData);
    const [userExists, setUserExists] = useState(null);
    const [detailsFormData, updateDeatilsFormData] = useState(initialDetailsFormData);
    const [defaultNameValue, setDefaultNameValue] = useState("");
    const [defaultEmailValue, setDefaultEmailValue] = useState("");
    const [loading, setLoading] = useState("");
    const [showDetails,SetshowDetails] = useState(false);

    useEffect(() => {
        if (formData.phoneno.length === 10) {
            loadHandler();
        }
    }, [formData.phoneno]);

    const loadHandler = () => {
        let userinput = "+91" + formData.phoneno;
        checkifuserexistToPay({ setUserExists, userinput ,SetshowDetails , setDefaultEmailValue , setDefaultNameValue});

    }

    const detailsInputHandler = (e) => {
        updateDeatilsFormData({
            ...detailsFormData,
            [e.target.name]: e.target.value.trim(),
        })
    }

    const handleChangeInputHandler = (event) => {
        updateFormData({
            ...formData,
            [event.target.name]: event.target.value.trim(),
        });
        updateDeatilsFormData({
            ...detailsFormData,
            [event.target.name]: event.target.value.trim(),
        })
    }
    const [bookingStatus, setBookingStatus] = useState("notBooked");
    const closeFormHandler = () => {
        props.close();
    }

    const PayInitiateHandler = async () => {
        setBookingStatus("booking");
        const price = props.topperProfile.hourlyrate * 100;
        const slotId = props.oneSlot.id;
        const temp = "+91" + String(formData.phoneno) + "-" +String(slotId);
        const hash = btoa(temp);
        const transactionid = "TRHT" + "-" + hash;
        const merchantId = "M2284KCBRJKOP";
        const merchantUserId = "MUID" + uuidv4().toString(36).slice(-6);
        const phoneNumber = "+91" + formData.phoneno;
        

        let testCred = { "merchantId": "PGTESTPAYUAT", "apiKey": "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399", "payURL": "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay" }
        let prodCred = { "merchantId": "M2284KCBRJKOP", "apiKey": "2f031cba-3c25-4c5f-9f8a-f92a8bf7d459", "payURL": "https://api.phonepe.com/apis/hermes/pg/v1/pay" }

        const payload = {
            price: price,
            transactionid: transactionid,
            merchantId: merchantId,
            merchantUserId: merchantUserId,
            phoneNumber: phoneNumber,
            slotId: slotId
        }

        if (!userExists) {
            console.log("inside payform", detailsFormData);

            createaccountstudent({ formData: detailsFormData, setLoading });
            if (loading === "created") {
                console.log("User created",loading);
                paymentInitiate({ payload, setBookingStatus });
            }
        }
        else {
            console.log("inside else payform");
            paymentInitiate({ payload, setBookingStatus });
        }
    }

    
    useEffect(()=>{
        console.log(bookingStatus);
        if(bookingStatus==="booked"){
            const phoneno = formData.phoneno;
            const slotId = props.slot.id;
            transactionStatusPhonePe({phoneno,slotId});
        }
    },[bookingStatus])


    const loginHandler = () => {
        Logout();
        history.push("/app/home/slots");
    }
    return <div className={classes.bookingForm}>
        <div className={classes.formBox}>
            <div className={classes.formContentBox}>
                <div className={classes.closeButtonDiv}>
                    <button type="button" className={classes.closeButton} onClick={closeFormHandler}> X</button>
                </div>
                <div ><b> Proceed to Payment </b> </div>
                <div className={classes.mainContainer}>
                    <div className={classes.flagcode}>
                        <img className={classes.flagImg} src={flag} alt="logo" />
                        <div className={classes.countryCode}> +91 </div>
                    </div>
                    <input
                        className={classes.editmobileNuBox}
                        type="number"
                        placeholder=" Mobile Number.."
                        onChange={handleChangeInputHandler}
                        name="phoneno"
                    />
                </div>

                {
                    showDetails &&
                    <div className={classes.detailsContainer}>
                        <div className={classes.detailsLabel} style={{ fontSize: "18px", fontWeight: "bolder" }}>Name</div>
                        <input
                            className={classes.detailsInput}
                            type="text"
                            placeholder=" Enter your Name "
                            defaultValue={defaultNameValue}
                            onChange={detailsInputHandler}
                            name="username"
                            disabled={!!defaultNameValue} // Disable if there's a value
                        />
                    </div>
                }
                {
                    showDetails &&
                    <div className={classes.detailsContainer}>
                        <div className={classes.detailsLabel} style={{ fontSize: "18px", fontWeight: "bolder" }}>Email</div>
                        <input
                            className={classes.detailsInput}
                            type="email"
                            placeholder=" Enter your email "
                            defaultValue={defaultEmailValue}
                            onChange={detailsInputHandler}
                            name="email"
                            disabled={!!defaultEmailValue}
                        />
                    </div>
                }

                {
                    showDetails && userExists.usertype !== "Student-Id: 3" && userExists.message &&
                    
                    <div className={classes.errorContainer}>
                        Only Student can book a session
                    </div>
                }

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <h2> <FaRupeeSign /> {props.topperProfile.hourlyrate} </h2>
                </div>

                {bookingStatus === "notBooked" &&
                    <button className={classes.bookSlotButton}
                        type="button"
                        onClick={PayInitiateHandler}
                        style={(formData.phoneno.length === 10) ? activeColor : inActiveColor}
                        disabled={formData.phoneno.length === 10 ? false : true}
                    >
                        Pay Now
                    </button>
                }

                <div className={classes.checkBoxTermsAndConditions}>
                    <input type="checkbox" defaultChecked />
                    By continuing here you are agreeing to our
                    <div className={classes.tosContainer} onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        history.push("/TermsofUse");
                    }}>
                        &nbsp; terms of service&nbsp;
                    </div>
                    and&nbsp;
                    <div className={classes.tosContainer} onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        history.push("/Privacypolicy");
                    }}>
                        privacy policy

                    </div>.
                </div>
                {bookingStatus === "booking" &&
                    <button className={classes.bookSlotButton} type="button" disabled={true}  >Booking...</button>
                }
                {bookingStatus === "booking" && 
                    <FadeLoader color={color} loading={true} css={override} size={50} />
                
                }
                {bookingStatus === "booked" &&
                    <button className={classes.bookSlotButton} type="button" disabled={true}  >Booked <GiCheckMark /> </button>
                }
                {bookingStatus === "booked" &&
                    <button type="button" className={classes.loginToMeetingLinkButton} onClick={loginHandler}>
                        Login to view meeting link
                    </button>
                }
            </div>
        </div>
    </div>
}

export default BookingForm;
