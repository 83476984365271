
import { useHistory } from "react-router-dom";
import classes from "./ExamBoxSlide.module.css";

const OneExamCard = (props) => {

  const history = useHistory();

  const openNewURL = () => {

    //  window.open(props.card.link,'_blank');

  }



  return <div className={classes.card}>
    <img
      src={props.card.image}
      className={classes.img}
      style={{ aspectRatio: props.aspectRatio }}
    />
    <div className={classes.title}>{props.card.title}</div>
    <div className={classes.description1}>{props.card.description}</div>
    <div className={classes.ViewToppersBtn} onClick={openNewURL}>{props.card.viewDetails}</div>
  </div>

}

export default OneExamCard;
