import React,{useState} from 'react';
import classes from './UnitAboutIcon.module.css';
//import {MdContactMail} from 'react-icons/md';
//import CreateAchievementForm from './Forms/CreateAchievementForm';

import AddAchievementsForm from './AddAchievementsForm';

import { BsPersonLinesFill } from "react-icons/bs";
import { BsFillTrophyFill } from "react-icons/bs";

const UnitAboutIcon=(props)=>{

   const [showAddAchievementForm, setShowAddAchievementForm]= useState(false);
   const showFormHandler=()=>{
     setShowAddAchievementForm(true);
   }
   const closeFormHandler=()=>{
     setShowAddAchievementForm(false);
   }



  console.log("+++", props.userData);


return (

<div className={classes.unitAboutIcon}>

   <div> <BsFillTrophyFill className={classes.aboutIcon}/> 

	<div style={{fontWeight:"bold",color:"var(--themeColor)", fontSize:"20px",marginLeft:"5px"}}>
	{  props.userData.usertype.name==="Topper"? "ACHIEVEMENTS/SUCCESS":"TARGET EXAMS"}
        </div> 
   </div>
  
  <button className={classes.editButtonAbout} onClick={showFormHandler}>+Add </button>

   { showAddAchievementForm &&  <AddAchievementsForm userData={props.userData} onPress={closeFormHandler}/>}

</div>
);


}
export default UnitAboutIcon;
