import { useState, useEffect, useRef } from 'react';
import classes from "./App.module.css";
import ProfileCard from "./ProfileCard";
import Slots from "./Slot";

import { gettopperprofile } from '../../../../CommonApps/AllAPICalls';
import ReactPlayer from 'react-player';

import UnitBarInstDegree from '../UnitBarInstDegree';
import UnitBarExperience from '../UnitBarExperience';
import Faq from './Faq';


const App = (props) => {

    const [topperProfile, getTopperProfile] = useState(null);
    const bodyRef = useRef(null);
    const rightDivRef = useRef(null);
    const clickRef = useRef(null);

    const [isTopFixed, setIsTopFixed] = useState(false);
    const [isBottomFixed, setBottomFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (rightDivRef.current && bodyRef.current) {
                const bodyRect = bodyRef.current.getBoundingClientRect();
                const rightDivHeight = rightDivRef.current.offsetHeight;
                const viewportHeight = window.innerHeight;

                if (bodyRect.top <= 0 && bodyRect.bottom + 330 > viewportHeight) {
                    setIsTopFixed(true);
                    setBottomFixed(false);
                }
                else if (bodyRect.bottom <= viewportHeight + 330) {
                    setIsTopFixed(false);
                    setBottomFixed(true);
                }
                else {
                    setIsTopFixed(false);
                    setBottomFixed(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    useEffect(() => {

        let topperId = props.topperId;
        gettopperprofile({ topperId, getTopperProfile });

    }, []);


    console.log("topperProfile-- ", topperProfile);

    if (!topperProfile) {
        return <div>Loading...</div>;
    }

    const clickHandler = () => {
        if(clickRef.current){
            clickRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className={classes.profileParent}>

            <div className={classes.mainContainer}

                style={{
                    alignItems: isBottomFixed ? 'flex-end' : 'flex-start',
                    top: isTopFixed ? '0' : '',
                    right: '0',
                }}

            >

                <div className={classes.leftContainer} ref={bodyRef} style={{ position: 'relative' }}>
                    {topperProfile !== null &&
                        <ProfileCard topperProfile={topperProfile} />
                    }

                    <div className={classes.middleContainer}>
                        <div className={classes.aboutmeContainer}>
                            <div className={classes.aboutmeTitle}>About Me</div>
                            <div className={classes.aboutmeDiscription}> {topperProfile.about}</div>
                        </div>

                        <div className={classes.educationContainer}>
                            <div className={classes.educationTitle}>Education</div>
                            <div className={classes.educationDescription}>
                                <div className={classes.qualificationContainer}>{topperProfile.educationDegrees[0].degreename} </div>
                                <div className={classes.locationContainer}>{topperProfile.educationDegrees[0].institute}</div>
                                <div className={classes.yearContainer}>{topperProfile.educationDegrees[0].startYear} - {topperProfile.educationDegrees[0].endYear}</div>
                            </div>
                        </div>
                        <div className={classes.experienceContainer}>
                            <div className={classes.experienceTitle}>Experience</div>
                            <div className={classes.experienceDescription}>
                                {
                                    topperProfile.experience != null && topperProfile.experience.map((oneExp, index) => {
                                        return (
                                            <div className={classes.experienceCard}>
                                                <b>Organization:&nbsp;</b>{oneExp.organization}
                                                <br />
                                                <b>Position:&nbsp;</b>{oneExp.position}
                                                <br />
                                                <b>duration:&nbsp;</b>{oneExp.startYear} - {oneExp.endYear}
                                            </div>
                                        )

                                    })
                                }

                            </div>
                        </div>
                    </div>
                    {/* <div className={classes.midContainer}>
                        <div className={classes.mentorHeading}>
                            What Mentees are saying?
                        </div>
                        <div className={classes.mentorPoint}>
                            <div className={classes.mentorPointQuestion}>
                                Ajay Singh ( UPSC Aspirant)
                            </div>
                            <div className={classes.mentorPointAnswer}>
                                Pankaja Sir is truly the best of the best. He provides an order of magnitude more value than what you would expect ! Highly recommended speaking with Pankaja Sir.
                            </div>
                        </div>
                        <div className={classes.mentorPoint}>
                            <div className={classes.mentorPointQuestion}>
                                Upasana Mishra ( UPSC Aspirant)
                            </div>
                            <div className={classes.mentorPointAnswer}>
                                Pankaja Sir is truly the best of the best. He provides an order of magnitude more value than what you would expect ! Highly recommended speaking with Pankaja Sir.                        </div>
                        </div>
                    </div> */}
                    <Faq />
                    {/* <div className={classes.fqaContainer}>
                        <div className={classes.faqTitle}> FAQ </div>
                        <div className={classes.faqDescription}>
                            <div className={classes.faqDescriptionTitle}>
                                Common questions
                            </div>
                            <div className={classes.faqDescriptionPoints}>
                                <ul className={classes.faqList}>
                                    <li>
                                        <div className={classes.listText}>
                                            How does a 1:1 video consultation work?
                                        </div>


                                        <div className={classes.listSymbol}>
                                            +
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.listText}>
                                            How long are the sessions?
                                        </div>


                                        <div className={classes.listSymbol}>
                                            +
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.listText}>
                                            How much can we accomplish in a virtual session?
                                        </div>


                                        <div className={classes.listSymbol}>
                                            +
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className={classes.rightContainer}
                    ref={rightDivRef}
                    style={{
                        position: isTopFixed ? 'fixed' : 'absolute',
                        top: isTopFixed ? '0' : '',
                        right: '0',
                    }}>
                    {topperProfile !== null &&
                        <Slots topperId={topperProfile.id} topperProfile={topperProfile} />
                    }
                </div>


            </div>
            <div className={classes.bodyContainer}>
                <div className={classes.bookingCard}>
                    <div className={classes.bookSlotContainer}>
                        <div className={classes.priceContainer}>
                            Rs {topperProfile.hourlyrate} / 30 Mins
                        </div>
                        <div className={classes.bookButtonConatiner} onClick={clickHandler}>
                            Book a Session
                        </div>
                    </div>
                </div>

                <div className={classes.leftContainer} style={{ position: 'relative' }}>
                    {topperProfile !== null &&
                        <ProfileCard topperProfile={topperProfile} />
                    }

                    <div className={classes.middleContainer}>
                        <div className={classes.aboutmeContainer}>
                            <div className={classes.aboutmeTitle}>About Me</div>
                            <div className={classes.aboutmeDiscription}> {topperProfile.about}</div>
                        </div>

                        <div className={classes.educationContainer}>
                            <div className={classes.educationTitle}>Education</div>
                            <div className={classes.educationDescription}>
                                <div className={classes.qualificationContainer}>{topperProfile.educationDegrees[0].degreename} </div>
                                <div className={classes.locationContainer}>{topperProfile.educationDegrees[0].institute}</div>
                                <div className={classes.yearContainer}>{topperProfile.educationDegrees[0].startYear} - {topperProfile.educationDegrees[0].endYear}</div>
                            </div>
                        </div>
                        <div className={classes.experienceContainer}>
                            <div className={classes.experienceTitle}>Experience</div>
                            <div className={classes.experienceDescription}>
                                {
                                    topperProfile.experience != null && topperProfile.experience.map((oneExp, index) => {
                                        return (
                                            <div className={classes.experienceCard}>
                                                <b>Organization:&nbsp;</b>{oneExp.organization}
                                                <br />
                                                <b>Position:&nbsp;</b>{oneExp.position}
                                                <br />
                                                <b>duration:&nbsp;</b>{oneExp.startYear} - {oneExp.endYear}
                                            </div>
                                        )

                                    })
                                }

                            </div>
                        </div>
                    </div>
                    {/* <div className={classes.midContainer}>
                        <div className={classes.mentorHeading}>
                            What Aspirants are saying?
                        </div>
                        <div className={classes.mentorPoint}>
                            <div className={classes.mentorPointQuestion}>
                                Ajay Singh ( UPSC Aspirant)
                            </div>
                            <div className={classes.mentorPointAnswer}>
                                Pankaja Sir is truly the best of the best. He provides an order of magnitude more value than what you would expect ! Highly recommended speaking with Pankaja Sir.
                            </div>
                        </div>
                        <div className={classes.mentorPoint}>
                            <div className={classes.mentorPointQuestion}>
                                Upasana Mishra ( UPSC Aspirant)
                            </div>
                            <div className={classes.mentorPointAnswer}>
                                Pankaja Sir is truly the best of the best. He provides an order of magnitude more value than what you would expect ! Highly recommended speaking with Pankaja Sir.                        </div>
                        </div>
                    </div> */}
                    {/* <div className={classes.fqaContainer}>
                        <div className={classes.faqTitle}> FAQ </div>
                        <div className={classes.faqDescription}>
                            <div className={classes.faqDescriptionTitle}>
                                Common questions
                            </div>
                            <div className={classes.faqDescriptionPoints}>
                                <ul className={classes.faqList}>
                                    <li>
                                        <div className={classes.listText}>
                                            How does a 1:1 video consultation work?
                                        </div>


                                        <div className={classes.listSymbol}>
                                            +
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.listText}>
                                            How long are the sessions?
                                        </div>


                                        <div className={classes.listSymbol}>
                                            +
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.listText}>
                                            How much can we accomplish in a virtual session?
                                        </div>


                                        <div className={classes.listSymbol}>
                                            +
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>  */}
                    <Faq />
                </div>
                <div className={classes.rightContainer} ref={clickRef} >
                    {topperProfile !== null &&
                        <Slots topperId={topperProfile.id} topperProfile={topperProfile} />
                    }
                </div>
            </div>
        </div>
    );
}

export default App;
