import React,{useState, useEffect} from "react";
import classes from "./EduDegreeForm.module.css";

import {createachievement} from "../../../../CommonApps/AllAPICalls";
import {AiFillCloseCircle} from 'react-icons/ai';



const CreateCourseForm=(props)=>{



  const [ addDegreeStatus, setAddDegreeStatus] = useState("notAdded"); 

  const initialFormData = Object.freeze({        
           name: "",
           stream: "",
           year: "",
	   rank:"AIR-23"
        });



const [formData, updateFormData] = useState(initialFormData);

const handleChange1 = (e) => {

        updateFormData({
                        ...formData,
                        [e.target.name]: e.target.value.trim()
                });
        };







const handleSubmit = (e) => {
	  e.preventDefault();
	  //setShowForm(false);
	   
          if(formData.name===""){
		  alert('please enter exam name');
		  return null;
	  }

          //if(formData.stream===""){
          //        alert('please enter stream');
          //        return null;
          //}


	  if(formData.year===""){
                  alert('please enter exam year');
                  return null;
          }

	  if(formData.rank===""){
                  alert('please enter end year');
                  return null;
          }


         //setTimeout(() => {console.log('Hello, World!')}, 5000);
         // setShowForm(false);
         setAddDegreeStatus("adding");
         createachievement({formData});

	
	};

 
      const handleChange=(e)=>{

  
         console.log("name---: ", e.target.value);
         updateFormData({
                        ...formData,
                        [e.target.name]: e.target.value.trim()
                });


      }	


	console.log("formData: ", formData);





return(

<div className={classes.createTicketFormDivParent}>

   {/*!showForm &&  
	   <div className={classes.createTicketFormLoading}>

	   <FadeLoader color={color} loading={loading} css={""} size={50}  />
	    
	   <div className={classes.submittingDiv}> Creating . . . </div>
           </div>
   */}


   { 	
    <form className={classes.createTicketForm} onSubmit={handleSubmit}>

       {/*form close button below*/}	
       <div className={classes.closeButtonDiv}>
          <button onClick={props.onPress} className={classes.closeFormButton}> <AiFillCloseCircle className={classes.closeButtonIcon}/> </button>
       </div>	

 
       {/*logo and field title container below*/}
       <div className={classes.logoAndTitleContainer}>
		   { props.userData.usertype.name==="Topper" && <div className={classes.formTitleDiv}><i>  Add Exams Qualified </i></div>}
	           { props.userData.usertype.name==="Student" && <div className={classes.formTitleDiv}><i>  Add Target Exams </i></div>}

         

       </div>


       <div className={classes.name_div}>
          <div className={classes.name_txt}>
		 <span style={{color:"red"}}> * </span>   Name of Exam
	  </div>
          <div className={classes.name_inputDiv} >
            <input
              type="text"
              onChange={handleChange}
              name="name"
              className={classes.value_field}
              placeholder="e.g GATE"
              defaultValue=""
            />
         </div>
      </div>


      <div className={classes.name_div}>
          <div className={classes.name_txt}>Stream</div>
          <div className={classes.name_inputDiv} >
            <input
              type="text"
              onChange={handleChange}
              name="stream"
              className={classes.value_field}
              placeholder="e.g. Mechanical engineering"
              defaultValue=""
            />
         </div>
     </div>


    <div className={classes.yearsDiv}>

      <div className={classes.name_div}>
          <div className={classes.name_txt}> <span style={{color:"red"}}> * </span>Exam Year</div>
          <div className={classes.name_inputDiv} >
            <input
              type="number"
              onChange={handleChange}
              name="year"
              className={classes.value_field}
              placeholder="e.g. 2019"
              defaultValue=""
            />
         </div>
      </div>

     {  props.userData.usertype.name==="Topper" &&
      <div className={classes.name_div}>
          <div className={classes.name_txt}><span style={{color:"red"}}> * </span>Your rank</div>
          <div className={classes.name_inputDiv} >
            <input
              type="text"
              onChange={handleChange}
              name="rank"
              className={classes.value_field}
              placeholder="e.g. AIR-23"
              defaultValue=""
            />
         </div>
      </div>

     }

    </div>

     {/*
      <div className={classes.name_div}>
          <div className={classes.name_txt}>Marked Obtained</div>
          <div className={classes.name_inputDiv} >
            <input
              type="text"
              onChange={handleChange}
              name="marks"
              className={classes.value_field}
              placeholder="e.g.  CGPA: 8.1 OR  Marks: 80 % "
              defaultValue=""
            />
         </div>
       </div>
     */}


       { addDegreeStatus ==="added" &&
 
	       <div style={{margin:"auto",width:"80%"}}> Degree added successfully!</div>

       }


       <div className={classes.submitButtonDiv}>
       
          { addDegreeStatus ==="notAdded" &&
              <button type="submit"  className= {classes.submit_button} ><b>Add </b> </button>
          }

          { addDegreeStatus ==="adding" &&
              <button type="submit"  className= {classes.submit_button} disabled={true}><b>Adding ... </b> </button>
          }

          { addDegreeStatus ==="added" &&
              <button type="submit"  className= {classes.submit_button} onClick={props.onPress}><b>Close form </b> </button>
          }

       </div>

  </form>

   }

</div>	
);

}


export default CreateCourseForm;
