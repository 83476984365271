import { useEffect, useRef } from "react";
import classes from "./Margadarshak.module.css";
import Introduction from "./margadarshak/introduction";
import TopBlock from "./margadarshak/topBlock";
import ProgramHighlights from "./margadarshak/ProgramHighlights";
import HowItWorks from "./margadarshak/HowItWorks";
import Faq from "./margadarshak/Faq";
import WhyMargadharsak from "./margadarshak/WhyMargadharsak";
import Success from "./margadarshak/Success";
import Footer from "./margadarshak/Footer";
import MeetMentors from "./margadarshak/MeetMentors";

const Margadarshak = (props) => {

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);



  return (
    <div className={classes.mainContainer}>
      <Introduction />
      <TopBlock />
      <ProgramHighlights />
      <HowItWorks />
      <WhyMargadharsak />
      <Success />
      <MeetMentors />
      <Faq />
      <Footer />
    </div>
  )
}

export default Margadarshak;