import React from "react";

import classes from "./ProfileImageInfo.module.css";



const ProfileImageInfo = (props) =>{

  const formatDate = (timestamp) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const date = new Date(timestamp);

  // Extract the day
  const day = date.getDate();

  // Add the appropriate suffix for the day (e.g., 1st, 2nd, 3rd, 4th)
  const dayWithSuffix = (
    (day === 1 || day === 21 || day === 31) ? `${day}st` :
    (day === 2 || day === 22) ? `${day}nd` :
    (day === 3 || day === 23) ? `${day}rd` : `${day}th`
  );

  // Use the `toLocaleDateString` method with the modified day
  const formattedDate = date.toLocaleDateString('en-US', options).replace(`${day}`, `${dayWithSuffix}`);

  return formattedDate;
};



  const originalTime = props.userData !==null ? props.userData.date_joined : "2023-12-02T18:09:53.994085Z";
  const formattedTime = formatDate(originalTime);





return (

<div className={classes.profileImageInfo}>


                 <div className={classes.nameDiv}>
                        <i className={classes.name_i}><h3>{props.userData.usertitle}  {props.userData.firstname}  </h3> </i>
                 </div>
	  
	         <div className={classes.profileImageInfo_position}> 
	                 <span>Role </span> 
	                 <i> 
	                     <span className={classes.roleTxt}>
	                          { props.userData !==null && props.userData.usertype.id === 1 && "Admin"}
	                          {props.userData !==null && props.userData.usertype.id === 2 && "Topper"}
	                          {props.userData !==null && props.userData.usertype.id === 3 && "Student"}
	                     </span> 
	                 </i> 
	          </div>
	          { props.userData.usertype.name==="Topper" && props.userData.aprovedTopper==="Yes" &&
                  <div className={classes.profileImageInfo_status}
	               >  
	               <span>Status</span> 
	               {props.userData !==null && props.userData.aprovedTopper==="Yes"  && <i> active </i> }
	          </div>
		  }

                  { props.userData.usertype.name==="Topper" && props.userData.aprovedTopper==="No" &&
                  <div className={classes.profileImageInfo_statusNo}
                       >
                       <span>Status</span>
                       {props.userData !==null && props.userData.aprovedTopper==="No"  && <i> Pending </i> }
                  </div>
                  }






                  { props.userData.usertype.name==="Student" &&
                  <div className={classes.profileImageInfo_status}
                      
                       >
                       <span>Status</span> 
                       <i> active </i> 
                  </div>
                  }




                 <div className={classes.profileImageInfo_joiningDate}> <span>Joined Since </span> <i> <span className={classes.joinTxt}>

                 { formattedTime }

	        </span></i>  </div>








</div>
);

}

export default ProfileImageInfo;
