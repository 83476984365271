import { useEffect, useState, useRef } from "react";

import classes from "./Home.module.css";
import IntroductionBlock from "./IntroductionBlock";
import CenterColumnLeftRightGrid from "./CenterColumnLeftRightGrid";
import Testimonial from "./Testimonial";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import TeamBlock from "./TeamBlock";
import Appointment from "./Apointment";
import BlogAndNewsBlock from "./BlogAndNewsBlock";
import LeftImageRightText from "./LeftImageRightText";
import OneNewsBlock from "../News/OneNewsBlock";

import innerDiv from "../Website.module.css";

import FirstBlock from "./FirstBlock";
import SecBlock from "./SecondBlock";

import imageNews1 from "../News/A11.png";
import imageNews2 from "../News/A2 .jpg";
import Exam from "./choose_topper.gif";
import Exam2 from "./book_session.gif";
import Exam3 from "./attend_session.gif";

import get1to1guidance from './get1to1guidance.png';

import Exambox from "./Exambox";
import ToppersCard from "./ToppersCard";

import w1 from "./w5.png";
import w2 from "./w3.png";
import w3 from "./w2.png";
import w4 from "./w1.png";
import w5 from "./w4.png";
import w6 from "./w6.png";






import ChooseTopper from "./choose_topper.gif";

import WhyHelloToppersCard from "./WhyHelloToppersCard";
import HowitWorks from "./HowitWorks";

import ExamBoxMain from "./ExamBoxMain";
import BoxSlide from "./ExamBoxSlideBibhu";
import ImaBoxSlide from "../FindToppers/TopperProfile/Reshwanth/ExamBoxSlide";
import ToppersCardSlide from "./ToppersCardSlideBibhu";

import BookIcon1 from "./choose_a_topper.png";
import BookingIcon from "./book_a_session.png";
import UserBooking from "./book_a_session.png";

import { useHistory } from 'react-router-dom';



import cardImage1 from "./CATnew.jpg";
import cardImage2 from "./GATEnew.jpg";
import cardImage3 from "./UPSCnew.jpg";
import cardImage4 from "./OPSC (1).jpg";


import topperImg1 from "./Anshuman.jpeg";
import topperImg2 from "./Manas.jpeg";
import topperImg3 from "./Vikash.jpeg";
import topperImg4 from "./Pankaj.jpeg";
import topperImg5 from "./Ipsita.jpeg";



import HowItWorks from "./HowItWorks";



const cardsDataExam = [
  {
    title: "Online CAT Mentoring",
    description: "4 toppers",
    viewDetails: "View Toppers",
    color: "white",
    image: cardImage1,
    link: "https://hellotoppers.com/",
    value: "CAT"

  },
  {
    title: "Online GATE Mentoring",
    description: "1 topper",
    viewDetails: "View Toppers",
    color: "white",
    image: cardImage2,
    link: "https://hellotoppers.com/",
    value: "GATE"
  },
  {
    title: "Online UPSC Mentoring",
    description: "2 toppers",
    viewDetails: "View Toppers",
    color: "white",
    image: cardImage3,
    link: "https://hellotoppers.com/",
    value: "UPSC"
  },
  {
    title: "Online OPSC Mentoring",
    description: "3 topper",
    viewDetails: "View Toppers",
    color: "white",
    image: cardImage4,
    link: "https://hellotoppers.com/",
    value: "OPSC"

  },
];



const cardsDataToppers = [
  {
    id: "24",
    title: "Annshuman Sahu",
    description: " CAT-IIMA, 98%ile",
    viewDetails: "View Profile",
    color: "white",
    image: topperImg1,
    link: "https://hellotoppers.com/topperprofile/24/"
  },

  {
    id: "22",
    title: "Manas Panda",
    description: "UPSC-2012, AIR-5",
    viewDetails: "View Profile",
    color: "white",
    image: topperImg2,
    link: "https://hellotoppers.com/topperprofile/22/"
  },
  {
    id: "26",
    title: "Vikas Kumar",
    description: "GATE-EE, AIR-34",
    viewDetails: "View Profile",
    color: "white",
    image: topperImg3,
    link: "https://hellotoppers.com/topperprofile/26/"
  },
  {
    id: "3",
    title: "Pankaj Sahu",
    description: "UPSC -2018, AIR-79",
    viewDetails: "View Profile",
    color: "white",
    image: topperImg4,
    link: "https://hellotoppers.com/topperprofile/3/"
  },
  {
    id: "11",
    title: "Ipsita Sahu",
    description: "OPSC Mentoring",
    viewDetails: "View Profile",
    color: "white",
    image: topperImg5,
    link: "https://hellotoppers.com/topperprofile/11/"
  }
];











const steps = [
  {
    title: "Step 1 :",
    image: Exam,
    description:
      "Watch topper’s introduction profile and read reviews from other students",
  },
  {
    title: "Step 2 :",
    image: Exam2,
    description:
      "Schedule your 1:1 online session with your favourite topper at a time and date that suit you",
  },
  {
    title: "Step 3 :",
    image: Exam3,
    description:
      "Connect with your topper via a 1:1 online video session, and let the mentoring begin!",
  },
];





const Home = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % steps.length);
    }, 5000); // Adjust the interval duration as needed (e.g., every 5 seconds)

    return () => clearInterval(intervalId);
  }, [steps.length]);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);

  const history = useHistory();


  const getStartedHandler = () => {
    history.push("/registerasstudent");
  }











  return (
    <div className={classes.homeParent}>
      <div className={innerDiv.innerDiv}>
        <div className={classes.mainExamTitle}>
          Which exam are you Targeting?
        </div>

        <div className={classes.boxSlideDesktop}>
          <BoxSlide cardsData={cardsDataExam} aspectRatio="1.7/1" setSelectFindTopper={props.setSelectFindTopper} />
        </div>

      </div>

      <div className={classes.boxSlideMobile}>
        <BoxSlide setSelectFindTopper={props.setSelectFindTopper} cardsData={cardsDataExam} aspectRatio="1.7/1" />
      </div>

      <div className={innerDiv.innerDiv} >

        <div className={classes.mainExamTitle}>
          Connect With Toppers Who Knows The Journey
        </div>

        <div className={classes.boxSlideDesktop}>
          <ImaBoxSlide cardsData={cardsDataToppers} aspectRatio="1.0/1" />
        </div>

      </div>

      <div className={classes.boxSlideMobile}>
        <BoxSlide setSelectFindTopper={props.setSelectFindTopper} cardsData={cardsDataToppers} aspectRatio="1.0/1" />
      </div>

      {/*
 	 <div className={innerDiv.innerDiv}>  

         </div>
         */}
      <HowItWorks />


      <div className={innerDiv.innerDiv}>

        <div className={classes.mainWhytoppersContainer}>
          <div className={classes.whyToppersTitle}>Why HelloToppers?</div>

          <div className={classes.whyToppersContainer}>
            <div className={classes.paarent}>
              <div className={classes.logo}>
                <img src={w1} className={classes.imagesExam} />
              </div>
              <div className={classes.title}>Personalized Guidance:</div>
              <div className={classes.description}>
                Talk to toppers and make your exclusive study plan&amp; strategy under his guidance.
              </div>
            </div>

            <div className={classes.paarent}>
              <div className={classes.logo}>
                <img src={w2} className={classes.imagesExam} />
              </div>
              <div className={classes.title}>Smart Study</div>
              <div className={classes.description}>
                Know the right study material &amp; Key areas instead of spending time on random study
              </div>
            </div>

            <div className={classes.paarent}>
              <div className={classes.logo}>
                <img src={w3} className={classes.imagesExam} />
              </div>
              <div className={classes.title}> Tips and Tricks</div>
              <div className={classes.description}>
                Learn short cut tricks &amp; techniques from toppers to answer quickly and boost your score.
              </div>
            </div>

            <div className={classes.paarent}>
              <div className={classes.logo}>
                <img src={w4} className={classes.imagesExam} />
              </div>
              <div className={classes.title}>Expert’s Experience</div>
              <div className={classes.description}>
                Don’t wait to learn from own failure rather learn from experts' experience to qualify
                in 1st attempt.
              </div>
            </div>

            <div className={classes.paarent}>
              <div className={classes.logo}>
                <img src={w5} className={classes.imagesExam} />
              </div>
              <div className={classes.title}>Interative Learning:</div>
              <div className={classes.description}>
                Ask questions, seek clarification and receive immediate feedback from toppers.
              </div>
            </div>

            <div className={classes.paarent}>
              <div className={classes.logo}>
                <img src={w6} className={classes.imagesExam} />
              </div>
              <div className={classes.title}>Direction to Destination</div>
              <div className={classes.description}>
                Know the dos &amp; don’ts before &amp; during exam to safely reach your destination
              </div>
            </div>
          </div>
        </div>

        {/* <TeamBlock /> */}

        <Testimonial />

      </div>

      <div className={classes.bottombox}>
        <div className={classes.bTitle}>
          Every Month, Hundreds of Exam Aspirants Joining Our Community
        </div>
        <div className={classes.bdetails}>
          "Start your Preparation Journey with our Topper Community"
        </div>
        <button className={classes.bbutton} type="button" onClick={getStartedHandler}>Get Started</button>

      </div>




    </div>
  );
}

export default Home;
