import classes from './WhyMargadharsak.module.css';
import w1 from "./second.png";
import w2 from './fourth.png';
import w3 from './fifth.png';
import w4 from './sixth.png';
import w5 from './seventh.png';
import w6 from './eighth.png';



const WhyMargadharsak = () => {
    return (
        <div className={classes.mainContainer}>
            <div className={classes.mainWhytoppersContainer}>
                <div className={classes.whyToppersTitle}>Why MargDarshak?</div>

                <div className={classes.whyToppersContainer}>
                    <div className={classes.paarent}>
                        <div className={classes.logo}>
                            <img src={w1} className={classes.imagesExam} />
                        </div>
                        <div className={classes.title}>Expert Guidance
                        </div>
                        <div className={classes.description}>
                            Direct mentorship from toppers who’ve excelled in competitive exams.
                        </div>
                    </div>

                    <div className={classes.paarent}>
                        <div className={classes.logo}>
                            <img src={w2} className={classes.imagesExam} />
                        </div>
                        <div className={classes.title}>Accessible Learning
                        </div>
                        <div className={classes.description}>
                            Flexible online group sessions that students can use their mobile phones to attend from any locations.
                        </div>
                    </div>

                    <div className={classes.paarent}>
                        <div className={classes.logo}>
                            <img src={w3} className={classes.imagesExam} />
                        </div>
                        <div className={classes.title}>Comprehensive Support
                        </div>
                        <div className={classes.description}>
                            Study resources, doubts clearing, and personalized advice tailored to students' needs.
                        </div>
                    </div>

                    <div className={classes.paarent}>
                        <div className={classes.logo}>
                            <img src={w4} className={classes.imagesExam} />
                        </div>
                        <div className={classes.title}>Community Building</div>
                        <div className={classes.description}>
                            Connect with peers and build a supportive network of like-minded aspirants.
                        </div>
                    </div>

                    <div className={classes.paarent}>
                        <div className={classes.logo}>
                            <img src={w5} className={classes.imagesExam} />
                        </div>
                        <div className={classes.title}>Career Pathways
                        </div>
                        <div className={classes.description}>
                            Insight into exam success tips and tricks, guidance on career options and how to achieve them.
                        </div>
                    </div>

                    <div className={classes.paarent}>
                        <div className={classes.logo}>
                            <img src={w6} className={classes.imagesExam} />
                        </div>
                        <div className={classes.title}>Completely free & Impactful
                        </div>
                        <div className={classes.description}>
                            Focused on providing top-tier mentoring at no cost, by already successful exam toppers.                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default WhyMargadharsak;