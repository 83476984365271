import classes from './Faq.module.css';
import { useState } from 'react';
import { BsChevronDown } from "react-icons/bs";

const Faq = () => {
    const initialData = [
        {
            question: "1. What is the MargDarshak program?",
            answer:"The MargDarshak program is an initiative by Hellotoppers to provide mentorship and guidance to students from rural areas and marginalized communities in India. Through this program, successful toppers mentor these students, offering them the knowledge and support they need to excel in competitive exams and build successful careers."
        },
        {
            question: "2. Who can become a MargDarshak mentor?",
            answer:"MargDarshak topper mentors are individuals who have secured top ranks in various competitive exams and have a passion for giving back to society. These topper mentors may be current students or professionals who are willing to share their experiences and guide others on their educational journey. "
        },



        {
            question: "3. How does the MargDarshak program benefit students?",
            answer:"The program operates on an online mode to provides students with personalized guidance through virtual group sessions from experienced topper mentors who have successfully navigated the same challenges. Students receive valuable insights on exam preparation, career advice, and motivational support, which are often inaccessible in rural areas."
        },

        {
            question: "4. How can schools and colleges enroll their students in the MargDarshak program?",
            answer:
                "Schools and colleges can partner with HelloToppers by registering their institutions on our platform or they directly reach to us by filling up the form through our contact us page in our hellotoppers.com website . Once registered, they can enroll their students in the MargDarshak program by sharing the student registration link of hellotoppers.com website, giving them access to group mentoring sessions, study resources, and ongoing support from our mentors.",
        },
        {
            question: "5. Is there a fee for students to join the MargDarshak program?",
            answer:"For students from marginalized communities, the MargDarshak program is offered at completely free of cost. The primary aim is to make quality mentorship accessible to all, regardless of financial constraints.",
        },
        {
            question: "6. What kind of support will students receive through the MargDarshak program?",
            answer:"Students will receive group mentoring sessions, access to study tips and tricks published at the website. The support continues throughout their preparation period, ensuring they have the guidance they need at every step.",
        },
        {
            question: "7. How are the mentoring sessions conducted?",
            answer:"The mentoring sessions are conducted online through the HelloToppers platform. Students can join group sessions led by their assigned MargDarshak mentor, where they can interact, ask questions, and learn from both the mentor and their peers.",
        },
        {
            question: "8. What if a student needs additional help beyond the group sessions?",
            answer:"Students can reach out to their MargDarshak mentor through the platform for additional guidance in the next group online meeting or email us to share their issues. ",
        },
        {
            question: "9. How do I become a MargDarshak mentor?",
            answer:"If you are a successful exam topper and are passionate about helping others, you can apply to become a MargDarshak mentor by filling out the application form on our website. Once approved, you will undergo a brief orientation to understand the program’s goals and how to best support the students.",
        },
        {
            question: "10. Can students choose their MargDarshak mentor?",
            answer:"Students are typically grouped based on their exam goals and interests, and then assigned a topper mentor who best fits their needs. While direct selection by students is not always possible, we ensure that every student is matched with a mentor who has relevant experience and knowledge.",
        },
        {
            question: "11. What is the role of schools and colleges in the MargDarshak program?",
            answer:"Schools and colleges play a crucial role in identifying students who would benefit from the MargDarshak program and helping them register. Institutions can also provide feedback to help us continually improve the program and ensure it meets the needs of their students.",
        },
        {
            question: "12. How can I support the MargDarshak program if I’m not a mentor?",
            answer:"If you’re not a mentor but still want to support the MargDarshak program, you can contribute by spreading the word about the initiative, volunteering in other capacities, or donating to help cover the costs for students who may not be able to afford even the minimal fees.",
        },
        {
            question: "13. How many students can join during a particular online group session?",
            answer:"In the MargDarshak program, each online group session with a topper mentor can accommodate a maximum of 30 to 40 students. This limit ensures that every student receives personalized attention, allowing the mentor to effectively address their questions and provide tailored guidance.",
        }

       
    ];

    const [faqData, setFaqData] = useState(initialData);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

    const toggleAnswer = (index) => {
        setSelectedQuestionIndex(index === selectedQuestionIndex ? null : index);
    };

    return (
        <div className={classes.faqContainer}>
            <div className={classes.mainTitleContainer}>
                FAQ: MargDarshak Program

            </div>

            <div className={classes.mainContainer}>
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={classes.questionsContainer}
                        onClick={() => toggleAnswer(index)}
                    >
                        <div className={classes.queContainer}>
                            <div className={classes.que1}>{faq.question}</div>
                            <BsChevronDown className={classes.arrow} />
                        </div>

                        {selectedQuestionIndex === index && (
                            <div className={classes.ans1}>{faq.answer}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}


export default Faq