import classes from './ProgramHighlights.module.css';
import img1 from "./first.png";
import img2 from "./second.png";
import img3 from './third.png';
import innerDiv from "../../../../Website.module.css";



const ProgramHighlights = () => {


    return <div className={classes.HowitWorksContainer}>
        <div className={classes.HowitworksTitle}>Program Highlights </div>

        <div className={innerDiv.innerDiv}>

            <div className={classes.helloTopContainer}>
                <div className={classes.paarent}>

                    <div className={classes.logo}>
                        <img src={img1} className={classes.imagesExam} />
                    </div>
                    <div className={classes.description}>
                        Focus on students from underserved communities.
                    </div>
                </div>

                <div className={classes.paarent}>

                    <div className={classes.logo}>
                        <img src={img2} className={classes.imagesExam} />
                    </div>
                    <div className={classes.description}>
                        Access to quality mentoring from top exam achievers.
                    </div>
                </div>

                <div className={classes.paarent}>

                    <div className={classes.logo}>
                        <img src={img3} className={classes.imagesExam} />
                    </div>
                    <div className={classes.description}>
                        Encourages aspirants to realize their potential and achieve success.
                    </div>
                </div>

            </div>


        </div>

    </div>


}


export default ProgramHighlights;
