import { useState, useEffect } from 'react';
import classes from "./Slot.module.css";
import { getalltopperslots, getdayslots, getdayslotsbyId } from '../../../../CommonApps/AllAPICalls';
import OneSlot from './OneSlot';

const Slots = (props) => {


  const currentDateConst = new Date();

  const [currentDate, setCurrentDate] = useState(new Date());

  const formattedDate = currentDate.toISOString().split('T')[0];

  const [userDaySlots, getUserDaySlots] = useState(null);

  const [pageNo, setPageNo] = useState(1);

  const daySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const formattedDisplayDate = currentDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });


  const day = currentDate.getDate();
  const formattedDateForTop = `${day}${daySuffix(day)} ${formattedDisplayDate.split(" ")[0]} ${formattedDisplayDate.split(" ")[2]}`;




  useEffect(() => {

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let topperId = props.topperId;
    getdayslotsbyId({ getUserDaySlots, topperId, formattedDate, timezone, pageNo });

  }, [pageNo, formattedDate, props.rerender]);





  const nextDayHandler = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    setCurrentDate(nextDate);
  }

  const prevDayHandler = () => {

    const prevDate = new Date(currentDate);
    prevDate.setDate(currentDate.getDate() - 1);
    setCurrentDate(prevDate);



  }


  let today = (currentDateConst === currentDate);










  console.log("userDaySlots: ", userDaySlots);


  return <div className={classes.slotsContainer}>
    <div className={classes.leftContainer}>

      <div className={classes.bookSlotContainer}>
        <div className={classes.priceContainer}>
          Rs {props.topperProfile.hourlyrate} / 30 Mins
        </div>
        <div className={classes.bookButtonConatiner}>
          Book a Session
        </div>
      </div>
      {/* <div className={classes.videoMentoringContainer}>
        1:1 Video Mentoring & Guidance
      </div> */}
      <div className={classes.availableContainer}>
        Available slots
      </div>

      <div className={classes.nextPreButtonsDiv}>
        <button type="button" className={classes.navButton} onClick={prevDayHandler}>
          Prev Day
        </button>
        <div className={classes.dateText}> <span>{formattedDateForTop}</span> </div>
        <button type="button" className={classes.navButton} onClick={nextDayHandler}>
          Next day
        </button>
      </div>
      <div className={classes.slotsCard}>
        {userDaySlots !== null && userDaySlots.results
          .slice() // Create a copy of the array to avoid mutating the original array
          .sort((a, b) => new Date(a.datetime) - new Date(b.datetime)) // Sort in ascending order based on dateTime
          .map((oneSlot, index) => {

            if (oneSlot.students.length === 0) {
              return <OneSlot key={index}
                oneSlot={oneSlot}
                topperProfile={props.topperProfile}
              />
            }
            return null;

          })
        }
      </div>





      {/* <div className={classes.bookingContainer}>
        <div className={classes.priceContainer}>
          199 / 30 mins
        </div>
        <div className={classes.bookButton}>
          Book Now
        </div>
      </div> */}

    </div>
    <div className={classes.rightContainer}>
      <div className={classes.headingContainer}>
        What To Expect
      </div>
      <div className={classes.sessionContainer}>
        <div className={classes.titleContainer}>
          In 30 minute session
        </div>
        <ul className={classes.pointsContainer}>
          <li className={classes.eachContainer}>
            Learn the proven strategy of exam Preparation
          </li>
          <li className={classes.eachContainer}>
            Know the right study materials and key areas

          </li>
          <li className={classes.eachContainer}>
            Learn the dos /don’ts before & during exam.
          </li>
          <li className={classes.eachContainer}>
            Get motivation and Tips to boost your score.

          </li>
          <li className={classes.eachContainer}>
            Clarify any other queries related to exam
          </li>
        </ul>
      </div>
    </div>


  </div>;
}
export default Slots;
