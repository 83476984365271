import classes from "./Disclaimer.module.css";

function Disclaimer() {
  return (
    <div className={classes.parent}>
      <div className={classes.mainContainer}>
        <div className={classes.titleheading}>Disclaimer for HelloToppers</div>
        <div className={classes.headingContainer}>
          The information, guidance, and mentoring services provided on the HelloToppers platform are intended to support students in their exam preparation and career planning journey. However, HelloToppers does not guarantee specific outcomes, results, or exam success. Participation in our mentoring programs, including 1:1 sessions, group sessions, and any advice received from Topper mentors, is entirely voluntary and based on the personal experiences of the Topper mentors.
        </div>

        <div className={classes.titleheading}>
          Important Points:
        </div>

        <div className={classes.heading1}>1. Topper Mentor Advice and Guidance:</div>

        <div className={classes.block1}>
          <div className={classes.dot2}></div>

          <div className={classes.details1}>
            The advice, strategies, and recommendations offered by our Topper mentors are based on their own exam and career experiences. While we strive to provide high-quality and relevant guidance, individual results may vary. Students are encouraged to use their judgment and seek additional resources when making important decisions.
          </div>
        </div>


        <div className={classes.heading1}>2. Educational Content:</div>

        <div className={classes.block1}>
          <div className={classes.dot}></div>

          <div className={classes.details1}>
            All content, including videos, articles, blogs, and educational resources available on HelloToppers, is meant for informational purposes only. HelloToppers does not provide any official curriculum or standardized educational material, and we are not affiliated with any examination board or institution.
          </div>
        </div>

        <div className={classes.heading1}>3. Mentor Credentials:</div>

        <div className={classes.block1}>
          <div className={classes.dot2}></div>

          <div className={classes.details1}>
            While HelloToppers verifies the credentials of its Topper mentors to the best of our ability, we do not warrant the accuracy, completeness, or reliability of the information shared by mentors during sessions or on our platform.
          </div>
        </div>

        <div className={classes.heading1}>4. No Professional Advice: </div>

        <div className={classes.block1}>
          <div className={classes.dot2}></div>

          <div className={classes.details1}>
            The mentoring services provided on HelloToppers do not constitute professional advice in areas such as legal, financial, medical, or psychological counseling. For professional advice, please consult a qualified expert in the respective field.
          </div>
        </div>


        <div className={classes.heading1}>5. Third-Party Links and Content:</div>

        <div className={classes.block1}>
          <div className={classes.dot}></div>

          <div className={classes.details1}>
            HelloToppers may provide links to third-party websites or resources. These links are provided for convenience only, and HelloToppers is not responsible for the content, products, or services available on those sites.
          </div>
        </div>

        <div className={classes.heading1}>6. Platform Use:</div>

        <div className={classes.block1}>
          <div className={classes.dot2}></div>

          <div className={classes.details1}>
            By using the HelloToppers platform, you agree to take full responsibility for your use of the services. HelloToppers, its founders, mentors, employees, or affiliates are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the platform.
          </div>
        </div>

        <div className={classes.heading1}>7. No Guarantees:</div>

        <div className={classes.block1}>
          <div className={classes.dot}></div>

          <div className={classes.details1}>
            HelloToppers does not guarantee admission into any educational institution, passing of any exams, or employment success as a result of using our services.
          </div>
        </div>
        <div className={classes.heading1}>
          Note:
        </div>
        <div className={classes.block1}>
          <div className={classes.dot}></div>

          <div className={classes.details1}>
            This disclaimer is subject to change without prior notice. Please review this section periodically to stay informed about any updates.          </div>
        </div>
        <div className={classes.headingContainer} style={{marginBottom:"20px",display:"flex",justifyContent:"center",fontWeight:"bolder",fontSize:"17px"}}>
          If you have any questions regarding this disclaimer, please contact us at support@hellotoppers.com.

        </div>

      </div>
    </div>
  );
}
export default Disclaimer;
