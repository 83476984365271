import classes from './HowItWorks.module.css';
import { GiCheckMark } from "react-icons/gi";


const HowItWorks = () => {
    return (
        <div className={classes.howitworks}>
            <div className={classes.howitworksTitle}>How it Works ?</div>
            <div className={classes.lastContainer}>
                <div className={classes.mainCircle}>
                    <div className={classes.f_points_star}>
                        <GiCheckMark size={30} style={{ color: "green" }} />
                    </div>
                </div>
                <div className={classes.Acontaienr}>
                    <div className={classes.titleA}>Step 1: Registration</div>
                    <div className={classes.desA}>
                        Students or institutions register for the program.
                    </div>
                </div>
            </div>

            <div className={classes.lastContainer}>
                <div className={classes.mainCircle}>
                    <div className={classes.f_points_star}>
                        <GiCheckMark size={30} style={{ color: "green" }} />
                    </div>
                </div>

                <div className={classes.Acontaienr}>
                    <div className={classes.titleA}>Step 2: Topper Mentor Match</div>
                    <div className={classes.desA}>
                        Students are matched with topper mentors based on their exam and career interests.
                    </div>
                </div>
            </div>

            <div className={classes.lastContainer}>
                <div className={classes.mainCircle}>
                    <div className={classes.f_points_star}>
                        <GiCheckMark size={30} style={{ color: "green" }} />
                    </div>
                </div>

                <div className={classes.Acontaienr}>
                    <div className={classes.titleA}>Step 3: Group Mentoring Sessions</div>
                    <div className={classes.desA}>
                        Interactive online sessions where mentors guide students on exam preparation strategies, career advice, and personal development.                    </div>
                </div>
            </div>

            <div className={classes.lastContainer}>
                <div className={classes.mainCircle}>
                    <div className={classes.f_points_star}>
                        <GiCheckMark size={30} style={{ color: "green" }} />
                    </div>
                </div>

                <div className={classes.Acontaienr}>
                    <div className={classes.titleA}>Step 4: Follow-up & Resources</div>
                    <div className={classes.desA}>
                        Continuous support with access to recorded sessions, notes, and other educational resources.                    </div>
                </div>
            </div>
            <div className={classes.lastContainer}>
                <div className={classes.mainCircle}>
                    <div className={classes.f_points_star}>
                        <GiCheckMark size={30} style={{ color: "green" }} />
                    </div>
                </div>
                <div className={classes.Acontaienr}>
                    <div className={classes.titleA}>Step 5: Feedback & Improvement</div>
                    <div className={classes.desA}>
                        Students and topper mentors provide feedback to improve the program and ensure its effectiveness.                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;