import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import classes from './SuccessPage.module.css';
import { GiCheckMark } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import { paymentStatusPhonepay, transactionStatusPhonePe } from '../CommonApps/AllAPICalls';
import { waitForElement } from '@testing-library/react';

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { successPage } = useParams(); // Retrieve successPage from URL parameters
  const history = useHistory();
  const [data,setData] = useState(null);


  useEffect(() => {
    console.log(successPage);
    paymentStatusPhonepay(successPage,setData);

  }, [successPage]);

  const loginHandler = () => {
    history.push('/app/home/slots');
  };

  useEffect(()=>{
    if (data!==null && data.data.data.code === "PAYMENT_SUCCESS" ){
      const hash = successPage.split("-");
      const decrypt = atob(hash[1]);
      const phoneno = decrypt.split("-")[0];
      const slotId = decrypt.split("-")[1];
      transactionStatusPhonePe({ phoneno , slotId });
    }
  },[data])

  console.log(data);
  
  if(data===null){
    return(
      <div className={classes.successPage}>
        Loading...
      </div>
    )
  }else{
    console.log(data.data.data.code);
  }

  return (
    <div className={classes.successPage}>
      {error && (
        <div className={classes.errorContainer}>
          <FaTimes style={{ color: "red" }} size={20} />
          <span>{error}</span>
        </div>
      )}
      {data!=null && data.data.data.code === "PAYMENT_SUCCESS" && 
        <div className={classes.mainContainer}>
          <div className={classes.topDiv}>
            <h1>Congratulations!</h1>
          </div>
          <div className={classes.topDiv}>
            <span style={{ fontSize: "20px" }}>Your payment was successful</span>
            <GiCheckMark style={{ color: "green", marginLeft: "10px" }} size={20} />
          </div>
          <div className={classes.topDiv}>
            Login to view your meeting link
          </div>
          <button type="button" className={classes.loginButton} onClick={loginHandler}>
            Login
          </button>
        </div>
      }
    </div>
  );
};

export default SuccessPage;
