import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import sha256 from "crypto-js/sha256";





const PhonePe=()=>{




   const payHandler=async()=>{

    const payload = {
         "merchantId": "PGTESTPAYUAT",
         "merchantTransactionId": "MT7850590068188104",
         "merchantUserId": "MUID123",
         "amount": 10000,
         "redirectUrl": "http://localhost:3000/success",
         "redirectMode": "REDIRECT",
         "callbackUrl": "https://webhook.site/callback-url",
         "mobileNumber": "9999999999",
         "paymentInstrument": {
                 "type": "PAY_PAGE"
         }
      }

       const dataPayload = JSON.stringify(payload);
      console.log(dataPayload);

      const dataBase64 = Buffer.from(dataPayload).toString("base64");
      console.log(dataBase64);

      const fullURL =
        dataBase64 + "/pg/v1/pay" + "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399";
      const dataSha256 = sha256(fullURL);

      const checksum = dataSha256 + "###" + "1";
      console.log("c====",checksum);


      const UAT_PAY_API_URL =
    "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";

      const response =await  axios.post(
       UAT_PAY_API_URL,
         {
          request: dataBase64,
         },
         {
           headers: {
               accept: "application/json",
               "Content-Type": "application/json",
               "X-VERIFY": checksum,
              },
         }
      );

      const redirect=response.data.data.instrumentResponse.redirectInfo.url;

      window.open(redirect,"__blank"); 


   }


       return <div>
             <h1>Phone pe</h1>
             <button type="button" onClick={payHandler}>
                  Pay Now
             </button>		
       </div>

}


export default PhonePe
