import Reacti,{useState, useEffect} from 'react';
import classes from './AccountingContentDiv.module.css';
import { FaPen } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import {editprice, putbanketail} from "../../CommonApps/AllAPICalls";






const SlotsContentDiv=(props)=>{

  console.log("props.userData: ", props.userData);

  const initialFormData = Object.freeze( {
                hourlyrate: props.userData.hourlyrate,
        });



   const initialBankData = Object.freeze( {
                bankname: props.userData.bankname,
	        branchname: props.userData.branchname,
	        accountholdername : props.userData.accountholdername,
	        accountnumber: props.userData.accountnumber,
	        ifsccode: props.userData.ifsccode
        });


  const [formData, updateFormData] = useState(initialFormData);

  const [bankFormData, updateBankFormData] = useState(initialBankData);	


  const handleChange = (e) => {
                updateFormData({
                        ...formData,
                        [e.target.name]: e.target.value.trim(),
                });
        };


  const handleChangeBank = (e) => {
                updateBankFormData({
                        ...bankFormData,
                        [e.target.name]: e.target.value.trim(),
                });
        };







  const [editMode, setEditMode] = useState(false);

  const [bankEditMode, setBankEditMode] = useState(false);


  const editButtonHandler=()=>{
    setEditMode(editMode=>!editMode);
  }


  const saveButtonHandler=()=>{

    setEditMode(editMode=>!editMode);

    editprice({formData});
  }



  const editBankHandler=()=>{
    setBankEditMode(bankEditMode=>!bankEditMode);
  }

  const saveBankHandler=()=>{
    setBankEditMode(bankEditMode=>!bankEditMode);
    putbanketail({bankFormData});
  }


   console.log("bankFormData: ", bankFormData);



return (

    <div className={classes.contentDiv}>
       	
       <div className={classes.monitorBoxes}>

                  <div className={classes.monitorOneBox}>
                       <div className={classes.boxnum}> <FaRupeeSign/> 2500 </div>
                       <div className={classes.boxtitle}> Amount Earned</div>
                  </div>
                  <div className={classes.monitorOneBox}>
                      <div className={classes.boxnum}> <FaRupeeSign/> 500 </div>
                       <div className={classes.boxtitle}> Amount Received</div>
                  </div>
                  <div className={classes.monitorOneBox}>
                      <div className={classes.boxnum}> <FaRupeeSign/> 2000 </div>
                       <div className={classes.boxtitle}> Amount Pending</div>
                  </div>
        </div>
      
        <div className={classes.setPriceBox}>
         
           <span> Price:   </span>

	    { !editMode && <>
                  <span className={classes.priceAmount}> <FaRupeeSign/> {props.userData.hourlyrate} / 30 min </span>
	          <button type="button" className={classes.editPriceButton} onClick={editButtonHandler}> Edit</button>
	       </>
	    }

            { editMode && <>

                   <span className={classes.priceAmount}> 
		      <FaRupeeSign/>  
                      <input
                            type="number"
                            onChange={handleChange}
                            name="hourlyrate"
                            className={classes.value_field}
                            placeholder="580"
                            defaultValue={formData.hourlyrate}
                            />


		    / 30 min </span>
                  <button type="button" className={classes.editPriceButton} onClick={saveButtonHandler}> Save</button> 

               </>
	    }


        </div>


       <div className={classes.BankDetails}>
	 { !bankEditMode && <button className={classes.editBankDetailButton} onClick={editBankHandler}> Edit </button> }
          
         { bankEditMode && <button className={classes.editBankDetailButton} onClick={saveBankHandler}> Save </button> }


	  <div className={classes.infoDivBank}> 
	       <span className={classes.bankTitle}> Bank Name: </span>
	       { !bankEditMode && <span style={{marginLeft:"10px"}}> <b> {props.userData.bankname} </b></span> }

	       { bankEditMode &&        <input
                            type="text"
                            onChange={handleChangeBank}
                            name="bankname"
                            className={classes.value_field}
                            placeholder="State Bank .."
                            defaultValue={bankFormData.bankname}
                            />
                }


	  </div>
          <div className={classes.infoDivBank}> 
               <span className={classes.bankTitle}> Branch Name: </span> 
               { !bankEditMode && <span style={{marginLeft:"10px"}}> <b> {props.userData.branchname} </b></span>}
	 
               { bankEditMode &&        <input
                            type="text"
                            onChange={handleChangeBank}
                            name="branchname"
                            className={classes.value_field}
                            placeholder="Ghatikia.."
                            defaultValue={bankFormData.branchname}
                            />
                }


          </div>
	  <div className={classes.infoDivBank}> 
               <span className={classes.bankTitle}> Account Holder Name: </span>
               { !bankEditMode && <span style={{marginLeft:"10px"}}> <b> {props.userData.accountholdername} </b></span> }
	       { bankEditMode &&        <input
                            type="text"
                            onChange={handleChangeBank}
                            name="accountholdername"
                            className={classes.value_field}
                            placeholder="Saswat .."
                            defaultValue={bankFormData.accountholdername}
                            />
                }

          </div>
	  <div className={classes.infoDivBank}> 
               <span className={classes.bankTitle}> Account Number: </span>
               { !bankEditMode && <span style={{marginLeft:"10px"}}> <b> {props.userData.accountnumber} </b></span>}
	       { bankEditMode &&        <input
                            type="number"
                            onChange={handleChangeBank}
                            name="accountnumber"
                            className={classes.value_field}
                            placeholder="7872672726"
                            defaultValue={bankFormData.accountnumber}
                            />
                }

          </div>
	  <div className={classes.infoDivBank}> 
               <span className={classes.bankTitle}> IFSc Code: </span>
               { !bankEditMode && <span style={{marginLeft:"10px"}}> <b> {props.userData.ifsccode} </b></span>}
	       { bankEditMode &&        <input
                            type="text"
                            onChange={handleChangeBank}
                            name="ifsccode"
                            className={classes.value_field}
                            placeholder="SBIN0009181"
                            defaultValue={bankFormData.ifsccode}
                            />
                }

          </div>
       </div>


    </div>

);

}


export default SlotsContentDiv;
