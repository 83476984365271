import React from 'react';
import classes from './UnitBarAchievement.module.css';
import {BsTrash} from 'react-icons/bs'

import {deleteexperience} from '../../../../CommonApps/AllAPICalls';




const UnitBarAchievement = (props)=>{




const deleteHandler=()=>{

  //deleteedudegree({edudegreeid});
  //window.location.reload(false);
  let Id=props.experience.id;
  deleteexperience({Id});
}



return (

<div className={classes.unitBarAchievement}>

   <div>
   <div className={classes.infoDiv1}> {props.experience.position} </div>
   <div className={classes.infoDiv2}> {props.experience.organization} </div>
   <div className={classes.infoDiv3}> {props.experience.startYear+" - "+ props.experience.endYear}  </div>
   </div>

   <button type="button" className={classes.deleteButton} onClick={deleteHandler}>
      <BsTrash/>
   </button>

</div>
);


}
export default UnitBarAchievement;
