import React,{useState} from 'react';
import classes from './UnitAboutIcon.module.css';
//import {MdContactMail} from 'react-icons/md';
//import CreateAchievementForm from './Forms/CreateAchievementForm';

import AddExperienceForm from './AddExperienceForm';

import { BsPersonLinesFill } from "react-icons/bs";


const UnitAboutIcon=(props)=>{

   const [showAddAchievementForm, setShowAddAchievementForm]= useState(false);
   const showFormHandler=()=>{
     setShowAddAchievementForm(true);
   }
   const closeFormHandler=()=>{
     setShowAddAchievementForm(false);
   }






return (

<div className={classes.unitAboutIcon}>

   <div> <BsPersonLinesFill className={classes.aboutIcon}/> <div style={{fontWeight:"bold",color:"var(--themeColor)", fontSize:"20px",marginLeft:"5px"}}>EXPERIENCE</div> </div>
  
  <button className={classes.editButtonAbout} onClick={showFormHandler}>+Add </button>

   { showAddAchievementForm &&  <AddExperienceForm userData={props.userData} onPress={closeFormHandler}/>}

</div>
);


}
export default UnitAboutIcon;
