import classes from "./TermsofUse.module.css";

function Privacypolicy() {
  return (
    <div className={classes.parent}>
      <div className={classes.mainContainer}>

        <div className={classes.titleheading}>HelloToppers Refund Policy: Effective Date: 01-01-2024</div>

        
	  <ol> 

             <li><b> Overview: </b>
HelloToppers is committed to providing a positive and valuable experience for students ("Mentees") using our platform. This refund policy outlines the conditions under which refunds may be granted for services provided on the HelloToppers platform.
            </li>


	     <li> <b>Refund Eligibility: </b>

                 <ol>

                    <li> <b> Unsatisfactory Service: </b> 
	              Refunds may be considered in cases where a Mentee finds the mentoring service to be unsatisfactory, provided that the Mentee can clearly demonstrate the service did not meet reasonable expectations.
	            </li>

                    <li> <b>Technical Issues: </b>
                      Refunds may be considered if technical issues on the HelloToppers platform significantly impact the Mentee's ability to receive or engage in the mentoring service.

	            </li>

                 </ol>	  

	     </li>


             <li> <b> Refund Request Process: </b>
                 <ol>
                    <li> <b> Contact Support: </b>
	               Mentees seeking a refund must contact the HelloToppers support team at support@hellotoppers.com within 6 hours of the mentoring session.
	            </li>


	             <li> <b> Explanation and Documentation: </b>
                       The Mentee must provide a detailed explanation of the reasons for the refund request, along with any supporting documentation, if applicable.        

	             </li>

                 </ol>
             </li>


              <li><b>  Refund Consideration: </b>
               
                   <ol>
                      <li> <b> Evaluation: </b>
	                  HelloToppers will evaluate the refund request based on the provided information and documentation.
	              </li>

	               <li> <b> Decision:</b>
	                Refund decisions will be made on a case-by-case basis. HelloToppers reserves the right to refuse a refund if the request does not meet the eligibility criteria.
                      </li>

	              <li> <b> Notification: </b>

	               The Mentee will be notified of the refund decision within 24 hours after the request is submitted.
                      </li>



                   </ol>

              </li>




               <li><b>  Refund Process: </b>

                   <ol>
                      <li> <b> Refund Method: </b>
	                Approved refunds will be processed using the original payment method used by the Mentee.
                      </li>

                       <li> <b> Timing:</b>
	                 Refunds will be processed within 48 hours of the approval notification.
                      </li>

                   </ol>

              </li>




              <li><b>  Non-Refundable Circumstances: </b>

                   <ol>
                      <li> <b> No-Show: </b>
	                Refunds will not be provided for mentoring sessions in which the Mentee is a no-show without prior notification.
                      </li>

                       <li> <b> Violation of Platform Policies:</b>
	                 Refunds will not be granted if the Mentee has violated the HelloToppers terms of use or code of conduct.
                      </li>




                   </ol>

              </li>

           
	     <li><b>  Contact Information: </b>

                   <ol>
                      <li> <b> Questions: </b>
	                For questions or concerns regarding this refund policy, please contact us at support@hellotoppers.com.
                      </li>




                   </ol>

              </li>













         </ol>

      </div>
    </div>
  );
}
export default Privacypolicy;
