import React from 'react';
import classes from './UnitBarAchievement.module.css';
import {BsTrash} from 'react-icons/bs'

import {deleteachievement} from '../../../../CommonApps/AllAPICalls';




const UnitBarAchievement = (props)=>{




const deleteHandler=()=>{

//deleteedudegree({edudegreeid});
//window.location.reload(false);
  let Id=props.achievement.id;
 deleteachievement({Id});
}



return (

<div className={classes.unitBarAchievement}>

   <div>
   <div className={classes.infoDiv1}> {props.achievement.name} </div>
   <div className={classes.infoDiv2}> {props.achievement.stream} </div>
   <div className={classes.infoDiv3}> {props.achievement.year} </div>
	{  props.userData.usertype.name==="Topper" && <div className={classes.infoDiv4}> {props.achievement.rank} </div> }
   </div>

   <button type="button" className={classes.deleteButton} onClick={deleteHandler}>
      <BsTrash/>
   </button>

</div>
);


}
export default UnitBarAchievement;
